<template>
    <div class="card" style="border-width: 0px" v-show="doneLoading">
        <div class="card-header px-0" style="background-color: #f4f5fa">
            <router-link :to="'/app/risikoanalyse'" href="#">{{mainTitle}} </router-link>
            <v-icon style="height: 12px" vertical-align="baseline" class="mb-1" name="chevron-right" /> {{ auction.title }}
            <router-link v-if="hasRole('Administrator')||hasRole('Einkäufer')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')" :to="'/app/ausschreibung/edit/'+auction.id" href="#"><b-icon icon="pencil-square" class="ml-1" scale="1.2"></b-icon></router-link>
        </div>
        <div class="card-body px-0" style="background-color: #f4f5fa">
            <p v-if="hasError">{{errorMessage}}</p>
            <b-form v-show="doneLoading && !hasError">
                <b-tabs>
                    <b-tab :title="overviewTitle" active>
                        <b-card-group deck>
                            <b-card :title="$t(`auction_view.headline`)" class="mb-3 shadow">
                                <b-form-group :label="$t(`auction_view.id`)" v-if="auction.id" label-for="auction-id">
                                    <b-row class="ml-3 text-muted" id="auction-id">{{ auction.id }}</b-row>
                                </b-form-group>

                                <b-form-group :label="$t(`auction_view.title`)" v-if="auction.title" label-for="auction-title">
                                    <b-row class="ml-3 text-muted" id="auction-title">{{ auction.title }}</b-row>
                                </b-form-group>

                                <b-form-group :label="$t(`auction_view.organizer`)" v-if="auction.organizer" label-for="auction-organizer">
                                    <b-row class="ml-3 text-muted" id="auction-organizer">{{ auction.organizer ? auction.organizer.company : '-' }}</b-row>
                                </b-form-group>

                                <b-form-group :label="$t(`auction_view.start`)" label-for="auction-start">
                                    <b-row class="ml-3 text-muted" id="auction-start">{{ auction.start_date | moment('DD.MM.YYYY HH:mm:ss') }}</b-row>
                                </b-form-group>

                                <b-form-group :label="$t(`auction_view.end`)" label-for="auction-end">
                                    <b-row class="ml-3 text-muted" id="auction-end">{{ auction.end_date | moment('DD.MM.YYYY HH:mm:ss') }}</b-row>
                                </b-form-group>

                                <b-form-group :label="$t(`auction_view.extend_after_bid`)" v-if="isAuction && (auction.extend_after_bid > 0)" label-for="auction-extend-after-bid">
                                    <b-row class="ml-3 text-muted" id="auction-extend-after-bid">{{ auction.extend_after_bid }} {{ $t("auction_view.minutes") }}</b-row>
                                </b-form-group>
                            </b-card>

                            <b-card v-if="isAuction" :title="$t(`auction_view.headline_conditions`)" class="mb-3 shadow">
                                <b-form-group :label="$t(`auction_view.delivery_conditions`)" v-if="auction.delivery_conditions" label-for="auction-delivery-conditions">
                                    <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.delivery_conditions)" id="auction-delivery-conditions"></b-row>
                                </b-form-group>

                                <b-form-group :label="$t(`auction_view.payment_conditions`)" v-if="auction.payment_conditions" label-for="auction-payment-conditions">
                                    <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.payment_conditions)" id="auction-payment-conditions"></b-row>
                                </b-form-group>

                                <b-form-group :label="$t(`auction_view.conditions`)" v-if="auction.conditions" label-for="auction-conditions">
                                    <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.conditions)" id="auction-conditions"></b-row>
                                </b-form-group>     
                            </b-card>

                            <b-card :title="$t(`auction_view.headline_contact`)" class="mb-3 shadow">
                                <b-form-group :label="$t(`auction_view.contact`)" v-if="auction.contact" label-for="auction-contact">
                                    <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.contact)" id="auction-contact"></b-row>
                                </b-form-group>

                                <b-form-group :label="$t(`auction_view.techcontact`)" v-if="auction.technical_contact" label-for="auction-technical-contact">
                                    <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.technical_contact)" id="auction-technical-contact"></b-row>
                                </b-form-group>
                            </b-card>

<!--                            <b-card :title="$t(`auction_view.bidstatus`)" :sub-title="$t(`auction_view.bidstatus_subtitle`)" class="mb-3 shadow" v-if="hasRole('Lieferant')">
                                <b-button class="button-margin" variant="danger" v-b-modal.decline_tender>{{ $t("auction_view.btn_decline") }}</b-button>
                                    <b-modal size="lg" id="decline_tender" centered="true" :title="$t(`auction_view.decline_header`)">
                                        <span v-html="$t('auction_view.decline_reason')" />
                                        <b-form-textarea v-model="bidstatus[0].cancellation_reason" @change="updateBidStatus" id="cancellation_reason" class="mt-2 mb-3" :placeholder="$t(`auction_view.decline_placeholder`)" :no-resize="true" :rows="5" style="background-color: #fdfdfd"></b-form-textarea>
                                        <template #modal-footer="{ ok, cancel, hide }">
                                            <b-button class="button-margin" variant="success" @click="ok()">
                                                {{ $t("auction_view.btn_declinesubmit") }}
                                            </b-button>
                                            <b-button class="button-margin" variant="danger" @click="cancel()">
                                                {{ $t("auction_view.btn_cancel") }}
                                            </b-button>
                                          </template>
                                    </b-modal>
                                <b-form-checkbox v-model="bidstatus[0].participation" name="check-button" class="mt-3" @change="updateBidStatus"> {{ $t("auction_view.bidstatus_participation") }}</b-form-checkbox>
                                <b-form-checkbox v-model="bidstatus[0].uploads_checked" name="check-button" @change="updateBidStatus"> {{ $t("auction_view.bidstatus_uploads_checked") }}</b-form-checkbox>
                                <b-form-checkbox v-model="bidstatus[0].bids_checked" name="check-button" @change="updateBidStatus"> {{ $t("auction_view.bidstatus_bids_checked") }}</b-form-checkbox>
                                <b-form-checkbox v-model="bidstatus[0].requirements_checked" name="check-button" @change="updateBidStatus"> {{ $t("auction_view.bidstatus_requirements_checked") }}</b-form-checkbox>

                               <b-form-checkbox v-model="bidstatus[0].answers_checked" name="check-button" @change="updateBidStatus"> Fragebögen sind vollständig ausgefüllt (Lieferantenselbstauskunft)</b-form-checkbox>
                                <b-form-checkbox v-model="bidstatus[0].lksg_checked" name="check-button" @change="updateBidStatus"> Angaben zum Lieferkettensorgfaltspflichtengesetz (LkSG) sind eingetragen</b-form-checkbox>

                                <b-form-textarea v-model="bidstatus[0].notes" id="notes" class="mb-3" placeholder="Notizen" :no-resize="true" :rows="2" :max-rows="2" style="background-color: #fdfdfd"></b-form-textarea>
                                <b-form-textarea v-model="bidstatus[0].description" id="description" class="mb-3" placeholder="Allgemeine Erläuterungen zum Angebot" :no-resize="true" :rows="2" :max-rows="2" style="background-color: #fdfdfd"></b-form-textarea>
                                <b-form-textarea v-model="bidstatus[0].alternative_offer" id="alternative_offer" class="mb-3" placeholder="Erläuterungen zum Alternativangebot" :no-resize="true" :rows="2" :max-rows="2" style="background-color: #fdfdfd"></b-form-textarea>
                                <b-form-textarea v-model="bidstatus[0].comments" id="comments" class="mb-3" placeholder="Kommentare zur Preiskalkulation" :no-resize="true" :rows="2" :max-rows="2" style="background-color: #fdfdfd"></b-form-textarea>

                                <br />
                                <b-button class="button-margin" block variant="primary" @click="updateBidStatus"><b-icon icon="ui-checks-grid" />{{ $t("auction_view.save_bidstatus") }}</b-button>
                            </b-card>
 

                            <b-card :title="$t(`auction_view.headline_status`)" class="mb-3 shadow" v-if="hasRole('Administrator') || hasRole('Einkäufer') || hasRole('Erweiterter Einkäufer') || hasRole('Berater')">
                                <template v-if="hasRole('Administrator') || hasRole('Einkäufer') || hasRole('Erweiterter Einkäufer') || hasRole('Berater')">
                                    <b-form-group :label="$t(`auction_view.samplingstatus`)" v-if="isAuction" label-for="auction-samplingstatus">
                                        <b-row class="ml-3 text-muted" id="auction-samplingstatus">
                                            <template v-if="auction.sampling_status === 0">{{ $t("auction_view.samplingstatus_0") }}</template>
                                            <template v-if="auction.sampling_status === 1">{{ $t("auction_view.samplingstatus_1") }}</template>
                                            <template v-if="auction.sampling_status === 2">{{ $t("auction_view.samplingstatus_2") }}</template>
                                            <template v-if="auction.sampling_status === 3">{{ $t("auction_view.samplingstatus_3") }}</template>
                                            <template v-if="auction.sampling_status === 4">{{ $t("auction_view.samplingstatus_4") }}</template>
                                        </b-row>
                                    </b-form-group>

                                    <b-form-group :label="$t(`auction_view.processstatus`)" v-if="isAuction" label-for="auction-processstatus">
                                        <b-row class="ml-3 text-muted" id="auction-processstatus">
                                            <template v-if="auction.process_status === 0">{{ $t("auction_view.processstatus_0") }}</template>
                                            <template v-if="auction.process_status === 1">{{editType}} {{ $t("auction_view.processstatus_1") }}</template>
                                            <template v-if="auction.process_status === 2">{{editType}} {{ $t("auction_view.processstatus_2") }}</template>
                                            <template v-if="auction.process_status === 3">{{editType}} {{ $t("auction_view.processstatus_3") }}</template>
                                            <template v-if="auction.process_status === 4">{{ $t("auction_view.processstatus_4") }}</template>
                                            <template v-if="auction.process_status === 5">{{ $t("auction_view.processstatus_5") }}</template>
                                            <template v-if="auction.process_status === 6">{{ $t("auction_view.processstatus_6") }}</template>
                                        </b-row>
                                    </b-form-group>

                                    <b-form-group :label="$t(`auction_view.contractnumber`)" v-if="isAuction" label-for="auction-contractnumber">
                                        <b-row class="ml-3 text-muted" id="auction-contractnumber">{{ auction.contract_number }}</b-row>
                                    </b-form-group>

                                    <b-form-group label="Preisalarm Start" v-if="isAuction" label-for="price-alert-startdate">
                                        <b-row class="ml-3 text-muted" id="price-alert-startdate">{{ auction.price_alert_startdate | moment('DD.MM.YYYY HH:mm:ss') }}</b-row>
                                    </b-form-group>
                                    <b-form-group label="Preisalarm Ende" v-if="isAuction" label-for="price-alert-enddate">
                                        <b-row class="ml-3 text-muted" id="price-alert-enddate">{{ auction.price_alert_enddate | moment('DD.MM.YYYY HH:mm:ss') }}</b-row>
                                    </b-form-group>
                                </template>
                            </b-card>    
-->        
                        </b-card-group>

                        <div v-if="hasRole('Administrator')||hasRole('Einkäufer')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')">
                           
                            <b-card :title="$t(`auction_view.notes`)" class="mb-3 shadow" v-if="hasRole('Administrator') || hasRole('Einkäufer') || hasRole('Erweiterter Einkäufer') || hasRole('Berater')">
                                <b-form-group :label="$t(`auction_view.notes`)" v-if="auction.notes" label-for="auction-notes">
                                    <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.notes)" id="auction-notes"></b-row>
                                </b-form-group>
                            </b-card>
                            
                            <div v-if="isAuction">
                                <b-card :title="$t(`auction_view.positions`)" class="mb-3 shadow" v-if="hasRole('Administrator') || hasRole('Einkäufer') || hasRole('Erweiterter Einkäufer') || hasRole('Berater')">
                                    <b-button v-b-toggle.help_positions pill size="sm" variant="outline-secondary" class="info-button"><em>i</em></b-button>
                                    <b-sidebar v-if="isAuction" right id="help_positions" :title="$t(`help.info`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">
                                        <div class="px-3 py-2">
                                            <b-list-group>
                                                <b-list-group-item class="user-select-all">{{ $t("help.positions_1") }}</b-list-group-item>
                                                <b-list-group-item class="user-select-all">{{ $t("help.positions_2") }}</b-list-group-item>
                                                <b-list-group-item class="user-select-all">{{ $t("help.positions_3") }}</b-list-group-item>
                                                <b-list-group-item class="user-select-all">{{ $t("help.positions_4") }}</b-list-group-item>                                            
                                            </b-list-group>
                                        </div>
                                    </b-sidebar>
                                    <b-sidebar v-if="!isAuction" right id="help_positions" :title="$t(`help.info`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">
                                        <div class="px-3 py-2">
                                            <b-list-group>
                                                <b-list-group-item class="user-select-all">{{ $t("help.positions_1") }}</b-list-group-item>
                                                <b-list-group-item class="user-select-all">{{ $t("help.positions_2") }}</b-list-group-item>
                                                <b-list-group-item class="user-select-all">{{ $t("help.positions_3") }}</b-list-group-item>                                          
                                            </b-list-group>
                                        </div>
                                    </b-sidebar>
                                    <div class="table-scrollable">
                                        <b-table striped hover :items="auction.items" :fields="auctionItemFieldsManagement" sort-by="order" v-if="!isAuction">
                                    </b-table>
                                    </div>
                                    <div class="table-scrollable" v-if="isAuction">
                                        <b-table striped hover :items="bidOverViewData.data" :fields="bidOverViewFields" sort-by="order" >
                                            <template #top-row="data">
                                                <td colspan="5">{{ $t("auction_view.rank") }}</td>
                                                <template v-for="field in data.fields">
                                                    <td v-bind:key="field.key" v-if="isUserColumn(field.key)">{{getRankForColumn(field.key)}}</td>
                                                </template>
                                                <td></td>
                                            </template>
                                            <template #cell(actual_value)="data">
                                                {{toCurrency(data.item.actual_value, auction.precision)}}
                                            </template>
                                            <template #custom-foot="data">
                                                <b-tr>
                                                    <b-td colspan="4">{{ $t("auction_view.sum") }}</b-td>
                                                    <template v-for="field in data.fields">
                                                        <b-td v-bind:key="field.key" v-if="isUserColumn(field.key) || field.key=='actual_value'">{{getSumForColumn(field.key)}}</b-td>
                                                    </template>
                                                    <b-td>€</b-td>
                                                </b-tr>
                                            </template>
                                        </b-table>
                                    </div>
                                </b-card>
                            </div>
                        </div>

                        <div v-if="hasRole('Lieferant') && auction.files && auction.files.length > 0">
                            <b-card :title="$t(`auction_view.documents`)" class="mb-3 shadow">
                                <div class="table-scrollable">
                                    <b-table striped hover :items="auction.files" :fields="restrictedFileTableFields" sort-by="order">
                                        <template #cell(actions)="data">
                                            <b-link class="m-1" href="#" @click="downloadFile(data.item)"><b-icon icon="download" scale="1.2"></b-icon></b-link>
                                        </template>
                                    </b-table>
                                </div>
                            </b-card>
                        </div>
                    </b-tab>

                    <b-tab :title="$t(`auction_view.tab_documents`)" v-if="hasRole('Administrator')||hasRole('Einkäufer')||hasRole('Berater')||hasRole('Erweiterter Einkäufer') ">
                        <b-card :title="$t(`auction_view.documents`)" class="mb-3 shadow">
                            <b-button v-b-toggle.help_documents pill size="sm" variant="outline-secondary" class="info-button"><em>i</em></b-button>
                            <b-sidebar right id="help_documents" :title="$t(`help.info`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">
                                <div class="px-3 py-2">
                                    <b-list-group>
                                        <b-list-group-item class="user-select-all">{{ $t("help.documents_1") }}</b-list-group-item>
                                        <b-list-group-item class="user-select-all">{{ $t("help.documents_2") }}</b-list-group-item>
                                        <b-list-group-item class="user-select-all">{{ $t("help.documents_3") }}</b-list-group-item>                                        
                                    </b-list-group>
                                </div>
                            </b-sidebar>
                            <br />
                            <div class="table-scrollable">
                                <b-table striped hover :items="auction.files" :fields="fileTableFields" sort-by="order">
                                    <template #cell(actions)="data">
                                        <b-link class="m-1" href="#" @click="downloadFile(data.item)"><b-icon icon="download" scale="1.2"></b-icon></b-link>
                                    </template>
                                </b-table>
                            </div>
                        </b-card>
                    </b-tab>

                    <b-tab :title="$t(`auction_view.tab_history`)" v-if="isAuction && (hasRole('Administrator')||hasRole('Berater')||hasRole('Einkäufer')||hasRole('Erweiterter Einkäufer'))">
                        <b-card :title="$t(`auction_view.card_history`)" class="mb-3 shadow">
                            <b-button v-b-toggle.help_history pill size="sm" variant="outline-secondary" class="info-button"><em>i</em></b-button>
                            <b-sidebar right id="help_history" :title="$t(`help.info`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">
                                <div class="px-3 py-2">
                                    <b-list-group>
                                        <b-list-group-item class="user-select-all">{{ $t("help.history_1") }}</b-list-group-item>
                                        <b-list-group-item class="user-select-all">{{ $t("help.history_2") }}</b-list-group-item>
                                        <b-list-group-item class="user-select-all">{{ $t("help.history_3") }}</b-list-group-item>                                        
                                    </b-list-group>
                                </div>
                            </b-sidebar>
                            <b-form-group :label="$t(`auction_view.historychart`)" label-for="history-chart-select">
                                <b-form-select id="history-chart-select" v-model="selectedGraphItem" :options="graphOptions" class="mb-3" @change="reloadGraph($event)"/>
                            </b-form-group>
                            <bid-history-chart chart-id="history-chart" :chart-data="bidHistoryData" :start-date="auction.start_date" :end-date="auction.end_date" :height="800" css-classes="chart-container" :options="chartOptions"/>
                        </b-card>
                    </b-tab>

                    <b-tab :title="$t(`user.tab_selfdisclosure`)" v-if="hasRole('Lieferant')">
                        <b-container>
                            <template v-for="questionnaire in supplierQuestionnaires">
                                <b-row v-bind:key="questionnaire.id">
                                    <b-col>
                                        <SupplierQuestionnaireComponent :questionnaire="questionnaire" :answers="supplierAnswers" />
                                    </b-col>
                                </b-row>
                            </template>
                        </b-container>
                    </b-tab>

                    <b-tab :title="$t(`auction_view.tab_uploaddocuments`)" v-if="hasRole('Lieferant') && auction.uploads_active">
                        <b-card :title="$t(`auction_view.card_uploadeddocuments`)" :sub-title="$t(`auction_view.card_subtitle_uploadeddocuments`)" class="mb-3 shadow">
                            <br />
                            <div class="table-scrollable">
                            <b-table striped hover :items="auction.uploads" :fields="uploadTableFields" sort-by="order">
                                <template #cell(actions)="data">
                                    <b-link class="m-1" href="#" @click="downloadUploadedFile(data.item)"><b-icon icon="download" scale="1.2"></b-icon></b-link>
                                    <b-link class="m-1" href="#" @click="deleteUpload(data.item)"><b-icon icon="trash" scale="1.2"></b-icon></b-link>
                                </template>
                                <template #cell(created_at)="data">
                                    {{data.item.created_at | moment('DD.MM.YYYY HH:mm')}}
                                </template>
                            </b-table>
                            </div>
                            <br/>
                            <b-button class="button-margin" variant="primary" @click="showFileForm"><b-icon icon="file-earmark-arrow-up" />{{ $t("auction_view.btn_uploaddocument") }}</b-button>
                            <br />
                            <br />
                            <span v-html="$t('auction_view.uploadnotes')"></span>
                        </b-card>
                    </b-tab>

                    <b-tab :title="$t(`auction_view.tab_bid`)" v-if="hasRole('Lieferant') && this.isAuction">
                        <b-card :title="$t(`auction_view.card_bids`)" :sub-title="$t(`auction_view.card_subtitle_bids`)" class="mb-3 shadow">
                            <b-row>
                                <b-col><b>{{ $t("auction_view.end") }}</b> {{this.auction.end_date | moment('DD.MM.YYYY HH:mm')}}</b-col>
                            </b-row>
                            <br />
                            <b-row>
                                <b-col><span v-html="$t('auction_view.biddingvariants')" /></b-col>
                            </b-row>
                            <br />
                            <b-row>
                                <b-col v-if="this.auction.bid_import_allow">
                                    <b-button class="button-margin" variant="primary" @click="showUpload()"><b-icon icon="file-earmark-excel" />{{ $t("auction_view.btn_bid_upload") }}</b-button>
                                </b-col>
                                <b-col>
                                    <b-button class="button-margin float-right" @click="updateUserBids()" ><b-icon icon="arrow-clockwise" />{{ $t("auction_view.btn_update") }}</b-button><br class="clearfix"/>
                                </b-col>
                            </b-row>
                            <br />
                            <b-form @submit.prevent="showBidConfirmation">
                                <div class="table-scrollable">
                                    <b-table striped hover :items="bidAuctionItems" :fields="bidAuctionItemFields" sort-by="order">
                                        <template #cell(top-row)="data">
                                            <td colspan="5">{{ $t("auction_view.sum") }}</td>
                                            <td>{{getSumOfItems()}}</td>
                                            <td v-if="auction.lowest_bid_visible"></td>
                                            <td v-if="auction.ranking_visible">{{currentRankingValue}}</td>
                                            <td></td>
                                        </template>
                                        <template #cell(amount)="data">
                                            {{formatThousands(data.item.amount)}} {{data.item.unit}}
                                        </template>
                                        <template #cell(sum)="data">
                                            {{getItemSum(data.item)}}
                                        </template>
                                        <template #cell(last_bid)="data">
                                            {{getLastBid(data.item)}}
                                        </template>
                                        <template #cell(lowest_bid)="data">
                                            {{getLowestBid(data.item)}}
                                        </template>
                                        <template #cell(ranking)="data">
                                            <template v-if="auction.ranking_position_visible">
                                            {{getRanking(data.item)}}
                                            </template>
                                        </template>
                                        <template #cell(new_bid)="data">
                                            <b-input-group :append="data.item.priceUnit" class="input-nowrap">
                                                <b-form-input v-model="data.item.new_bid" type="text" class="input-min-7rem" :state="!$v.bidAuctionItems.$error ? null : false"></b-form-input>
                                            </b-input-group>
                                        </template>
                                    </b-table>
                                </div>
                                <b-button class="button-margin" type="submit" variant="primary"><b-icon icon="check2-all" />{{ $t("auction_view.btn_bid") }}</b-button>
                            </b-form>
                            <br />
                            <span v-html="$t('auction_view.biddingnotes')" />
                        </b-card>
                    </b-tab>
<!--
                    <b-tab :title="$t(`auction_view.tab_tender`)" v-if="hasRole('Lieferant')">
                        <b-card :title="$t(`auction_view.card_tender`)" class="mb-3 shadow">
                            <span v-html="$t('auction_view.tendernotes')" />
                        </b-card> 
                    </b-tab>
-->
                    <b-tab :title="$t(`auction_view.tab_proposaldocuments`)" v-if="hasRole('Administrator')||hasRole('Einkäufer')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')">
                        <b-card :title="$t(`auction_view.card_proposaldocuments`)" class="mb-3 shadow">
                            <div class="table-scrollable">
                                <b-table striped hover :items="auction.uploads" :fields="uploadedTableFields" sort-by="order">
                                    <template #cell(actions)="data">
                                        <b-link class="m-1" href="#" @click="downloadUploadedFile(data.item)"><b-icon icon="download" scale="1.2"></b-icon></b-link>
                                    </template>
                                    <template #cell(created_at)="data">
                                        {{data.item.created_at | moment('DD.MM.YYYY HH:mm')}}
                                    </template>
                                </b-table>
                            </div>
                        </b-card>
                    </b-tab>

                    <b-tab :title="$t(`auction_view.tab_items`)" v-if="hasRole('Lieferant') && this.isAuction">
                        <b-card :title="$t(`auction_view.card_`)" class="mb-3 shadow">
                            <br />
                            <b-row>
                                <b-col><b>{{ $t("auction_view.tender_end") }}</b> {{this.auction.end_date | moment('DD.MM.YYYY HH:mm')}}</b-col>
                            </b-row>
                            <br />
 
                            <div class="table-scrollable">
                                <b-table striped hover :items="auction.items" :fields="auctionItemFields" sort-by="order">
                                    <template #cell(amount)="data">
                                        {{data.item.amount}} {{data.item.unit}}
                                    </template>
                                </b-table>
                            </div>
                        </b-card>
                    </b-tab>

                    <b-tab :title="$t(`auction_view.tab_regulations`)">
                        <b-card :title="$t(`auction_view.tab_regulations`)" class="mb-3 shadow">
                            <b-button v-b-toggle.help_regulations pill size="sm" variant="outline-secondary" class="info-button"><em>i</em></b-button>
                            <b-sidebar right id="help_regulations" :title="$t(`help.info`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">
                                <div class="px-3 py-2">
                                    <b-list-group>
                                        <b-list-group-item class="user-select-all">{{ $t("help.regulations_1") }}</b-list-group-item>
                                        <b-list-group-item class="user-select-all">{{ $t("help.regulations_2") }}</b-list-group-item>
                                        <b-list-group-item v-if="!hasRole('Lieferant')" class="user-select-all">{{ $t("help.regulations_3") }}</b-list-group-item>
                                        <b-list-group-item class="user-select-all">{{ $t("help.regulations_4") }}</b-list-group-item>                                        
                                    </b-list-group>
                                </div>
                            </b-sidebar>
                            <br />
                            <b-row class="ml-0 mr-2">
                                <b-col md>
                                    <b-row class="mr-4">
                                        <b-form-group
                                        :label="$t(`cfbcreate.card_quotation_requirements`)"
                                        label-size="lg"
                                        label-class="font-weight-bold pt-1"
                                        class="mb-3"
                                        v-if="auction.offer_form || auction.bid_binding_period || auction.currency || auction.award_model"
                                        >
                                            <b-form-group :label="$t(`cfbcreate.offer_form`)" v-if="auction.offer_form" label-for="auction-offer-form">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.offer_form)" id="auction-offer-form"></b-row>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.bid_binding_period`)" v-if="auction.bid_binding_period" label-for="auction-bid-binding-period">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.bid_binding_period)" id="auction-bid-binding-period"></b-row>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.currency`)" v-if="auction.currency" label-for="auction-currency">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.currency)" id="auction-currency"></b-row>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.award_model`)" v-if="auction.award_model" label-for="auction-award-model">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.award_model)" id="auction-award-model"></b-row>
                                            </b-form-group>
                                        </b-form-group>
                                    </b-row>
                                    <b-row class="mr-4">
                                        <b-form-group
                                        :label="$t(`cfbcreate.card_qualification_requirements`)"
                                        label-size="lg"
                                        label-class="font-weight-bold pt-1"
                                        class="mb-3"
                                        v-if="auction.certifications || auction.supply_chain"
                                        >
                                            <b-form-group :label="$t(`cfbcreate.certifications`)" v-if="auction.certifications" label-for="auction-certifications">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.certifications)" id="auction-certifications"></b-row>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.supply_chain`)" v-if="auction.supply_chain" label-for="auction-supply-chain">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.supply_chain)" id="auction-supply-chain"></b-row>
                                            </b-form-group>
                                        </b-form-group>
                                    </b-row>
                                    <b-row class="mr-4">
                                        <b-form-group
                                        :label="$t(`cfbcreate.card_quantityprice_information`)"
                                        label-size="lg"
                                        label-class="font-weight-bold pt-1"
                                        class="mb-3"
                                        v-if="auction.quantity_details || auction.freight_costs || auction.price_adjustment || auction.bonus_system || auction.other_price_regulations"
                                        >
                                            <b-form-group :label="$t(`cfbcreate.quantity_details`)" v-if="auction.quantity_details" label-for="auction-quantity-details">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.quantity_details)" id="auction-quantity-details"></b-row>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.freight_costs`)" v-if="auction.freight_costs" label-for="auction-freight-costs">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.freight_costs)" id="auction-freight-costs"></b-row>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.price_adjustment`)" v-if="auction.price_adjustment" label-for="auction-price-adjustment">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.price_adjustment)" id="auction-price-adjustment"></b-row>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.bonus_system`)" v-if="auction.bonus_system" label-for="auction-bonus-system">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.bonus_system)" id="auction-bonus-system"></b-row>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.other_price_regulations`)" v-if="auction.other_price_regulations" label-for="auction-other-price-regulations">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.other_price_regulations)" id="auction-other-price-regulations"></b-row>
                                            </b-form-group>
                                        </b-form-group>
                                    </b-row>
                                </b-col>
                                <b-col md>
                                    <b-row class="mr-4">
                                        <b-form-group
                                        :label="$t(`cfbcreate.card_logistical_requirements`)"
                                        label-size="lg"
                                        label-class="font-weight-bold pt-1"
                                        class="mb-3"
                                        v-if="auction.packing_labeling || auction.warehousing || auction.waste_management || auction.returns_process"
                                        >
                                            <b-form-group :label="$t(`cfbcreate.packing_labeling`)" v-if="auction.packing_labeling" label-for="auction-packing-labeling">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.packing_labeling)" id="auction-packing-labeling"></b-row>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.warehousing`)" v-if="auction.warehousing" label-for="auction-warehousing">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.warehousing)" id="auction-warehousing"></b-row>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.waste_management`)" v-if="auction.waste_management" label-for="auction-waste-management">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.waste_management)" id="auction-waste-management"></b-row>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.returns_process`)" v-if="auction.returns_process" label-for="auction-returns-process">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.returns_process)" id="auction-returns-process"></b-row>
                                            </b-form-group>
                                        </b-form-group>
                                    </b-row>
                                    <b-row class="mr-4">
                                        <b-form-group
                                        :label="$t(`cfbcreate.card_commercial_processing`)"
                                        label-size="lg"
                                        label-class="font-weight-bold pt-1"
                                        class="mb-3"
                                        v-if="auction.ordering_process || auction.goods_receipt_process || auction.complaint_management || auction.invoicing"
                                        >
                                            <b-form-group :label="$t(`cfbcreate.ordering_process`)" v-if="auction.ordering_process" label-for="auction-ordering-process">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.ordering_process)" id="auction-ordering-process"></b-row>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.goods_receipt_process`)" v-if="auction.goods_receipt_process" label-for="auction-goods-receipt-process">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.goods_receipt_process)" id="auction-goods-receipt-process"></b-row>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.complaint_management`)" v-if="auction.complaint_management" label-for="auction-complaint-management">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.complaint_management)" id="auction-complaint-management"></b-row>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.invoicing`)" v-if="auction.invoicing" label-for="auction-invoicing">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.invoicing)" id="auction-invoicing"></b-row>
                                            </b-form-group>
                                        </b-form-group>
                                    </b-row>
                                    <b-row class="mr-4">
                                        <b-form-group
                                        :label="$t(`cfbcreate.card_tenderterms`)"
                                        label-size="lg"
                                        label-class="font-weight-bold pt-1"
                                        class="mb-3"
                                        v-if="auction.contract_extension || auction.insurances || auction.guarantee_conditions || auction.commercial_conditions || auction.contract_terms"
                                        >
                                            <b-form-group :label="$t(`cfbcreate.contract_extension`)" v-if="auction.contract_extension" label-for="auction-contract-extension">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.contract_extension)" id="auction-contract-extension"></b-row>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.insurances`)" v-if="auction.insurances" label-for="auction-insurances">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.insurances)" id="auction-insurances"></b-row>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.guarantee_conditions`)" v-if="auction.guarantee_conditions" label-for="auction-guarantee-conditions">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.guarantee_conditions)" id="auction-guarantee-conditions"></b-row>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.commercial_conditions`)" v-if="auction.commercial_conditions" label-for="auction-commercial-conditions">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.commercial_conditions)" id="auction-commercial-conditions"></b-row>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.contract_terms`)" v-if="auction.contract_terms" label-for="auction-contract-terms">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.contract_terms)" id="auction-contract-terms"></b-row>
                                            </b-form-group>
                                        </b-form-group>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-tab>
                    <b-tab :title="$t(`cfbcreate.tab_user`)" v-if="(hasRole('Administrator')||hasRole('Berater')||hasRole('Einkäufer')||hasRole('Erweiterter Einkäufer'))">
                        <b-card :title="$t(`auction_view.card_user`)" class="mb-3 shadow">
                            <b-button v-b-toggle.help_assigneduser pill size="sm" variant="outline-secondary" class="info-button"><em>i</em></b-button>
                            <b-sidebar right id="help_assigneduser" :title="$t(`help.info`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">
                                <div class="px-3 py-2">
                                    <b-list-group>
                                        <b-list-group-item class="user-select-all"><span v-html="$t('help.assigneduser_1')" /></b-list-group-item>
                                        <b-list-group-item class="user-select-all"><span v-html="$t('help.assigneduser_2')" /></b-list-group-item>
                                        <b-list-group-item class="user-select-all"><span v-html="$t('help.assigneduser_3')" /></b-list-group-item>
                                        <b-list-group-item class="user-select-all"><span v-html="$t('help.assigneduser_4')" /></b-list-group-item>
                                        <b-list-group-item class="user-select-all"><span v-html="$t('help.assigneduser_5')" /></b-list-group-item>                                       
                                    </b-list-group>
                                </div>
                            </b-sidebar>
                            <div class="table-scrollable">
                                <b-table striped hover :items="users" :fields="userTableFields">
                                    <template #cell(accepted_terms_of_service)="data">
                                        <template v-if="data.item.accepted_terms_of_service === true">{{ $t("auction_view.accepted_tos_true") }}</template>
                                        <template v-if="data.item.accepted_terms_of_service === false">{{ $t("auction_view.accepted_tos_false") }}</template>
                                    </template>
                                </b-table>
                            </div>
                        </b-card>
                    </b-tab>

                    <b-tab title="Rückmeldungen" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Einkäufer')||hasRole('Erweiterter Einkäufer')">
                        <template v-for="questionnaire in questionnaireData.questionnaires">
                            <b-row>
                                <b-col>
                                    <AdminQuestionnaireComponent :questionnaire="questionnaire" :questionFields="questionnaireData.questionFields" />
                                </b-col>
                            </b-row>
                        </template>
                    </b-tab>

                </b-tabs>
            </b-form>
        </div>

        <b-modal ref="fileDialog" hide-footer :title="$t(`auction_view.modal_upload_title`)">
            <b-container>
                <b-form @submit.prevent="onFileUploadSubmit">
                    <b-row>
                        <b-form-group :label="$t(`auction_view.file_description`)" label-for="file-description">
                            <b-form-textarea id="file-description" v-model="currentFile.description" rows="3" max-rows="3" no-resize />
                        </b-form-group>
                    </b-row>
                    <b-row>
                        <b-form-group :label="$t(`auction_view.file_file`)" label-for="file-file">
                            <b-form-file id="file-file" v-model="currentFile.file" :placeholder="$t(`auction_view.placeholder_upload`)" accept/>
                        </b-form-group>
                    </b-row>
                    <b-row>
                        <b-button class="button-margin" type="submit"><b-icon icon="upload" />{{ $t("auction_view.btn_upload") }}</b-button>
                    </b-row>
                </b-form>
            </b-container>
        </b-modal>

        <b-modal ref="confirmBidDialog" hide-footer :title="$t(`auction_view.confirm_bid.title`)">
            <b-table striped hover :items="bidRequestItems" :fields="bidConfirmationTable">
                <template #cell(bid)="data">
                    {{formatCurrencyFromInteger(data.item.bid)}} &nbsp; {{data.item.priceUnit}}
                </template>
                <template #cell(sum)="data">
                    {{formatCurrencyFromInteger(data.item.sum)}}
                </template>
                <template #custom-foot="data">
                    <b-tr>
                        <b-td colspan="3">{{ $t("auction_view.confirm_bid.sum") }}</b-td>
                        <b-td>{{ getSumOfActiveBid() }}</b-td>
                    </b-tr>
                </template>
            </b-table>
            <b-container>
                <b-row>
                    <b-button class="button-margin" @click.prevent="bid()"><b-icon icon="check2-all" />{{ $t("auction_view.confirm_bid.btn_confirm") }}</b-button>
                </b-row>
            </b-container>
        </b-modal>

        <b-modal ref="confirmUploadDeletion" hide-footer :title="$t(`auction_view.modal_deletedocument_title`)">
            <div class="d-block text-center">
                <p>{{ $t("auction_view.delete_message1") }} <strong>'{{selectedUpload.filename}}'</strong> {{ $t("auction_view.delete_message2") }}</p>
            </div>
            <b-button class="button-margin mt-3" variant="outline-danger" block @click.prevent="deleteUploadedFile()">{{ $t("auction_view.btn_delete") }}</b-button>
        </b-modal>

        <BidUploadModal ref="bidUploadModal" :savedCallback="confirmUploadedBids" :auction="auction"/>

    </div>
</template>
<script>
    import BidUploadModal from './modules/BidUploadModal';
    import StatusPill from './modules/StatusPill';
    import callForBidsService from '../../../../services/CallForBidsService';
    import QuestionnaireService from '../../../../services/QuestionnaireService';
    import BidHistoryChart from '../../../charts/BidHistoryChart';
    import numberHelper from '../../../../helpers/numberHelper';
    import objectHelper from '../../../../helpers/objectHelper';
    import { dateHelper } from '../../../mixins/dateHelper';
    import {mapGetters, mapActions} from 'vuex';
    import { helpers, required} from 'vuelidate/lib/validators';
    import AdminQuestionnaireComponent from './Questionnaire';
    import SupplierQuestionnaireComponent from '../profile/Questionnaire';

    export default {
        name: 'callforbids-view',
        components: {
          BidHistoryChart,
          BidUploadModal,
          AdminQuestionnaireComponent,
          SupplierQuestionnaireComponent,
          StatusPill
        },
        mixins: [dateHelper],
        data() {
            return {
                polling: null,
                hasError: false,
                errorMessage: '',
                bidHistoryData: {},
                currentFile: {
                    description: ''
                },
                doneLoading: false,
                auction: {},
                bidAuctionItems: [],
                bidRequestItems: [],
                users: [],
                bidOverViewData: {},
                questionnaireData: {},
                selectedUpload: {},
                selectedGraphItem: 0,
                currentRanking: -1,
                isAuction: false,
                bidstatus: [],
                chartOptions: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [{
                            type: 'time',
                            time: {
                                tooltipFormat: "DD.MM.YYYY HH:mm:ss",
                                displayFormats: {
                                    second: "HH:mm:ss",
                                    minute: "DD.MM HH:mm",
                                    hour: "DD.MM HH:mm",
                                    day: "DD.MM",
                                    week: "d"
                                }
                            },
                            position: 'bottom',

                        }],
                        yAxes: [{
                            ticks: {
                                //min: 0
                            }
                        }]
                    }
                },
                supplierQuestionnaires: [],
                supplierAnswers: [],
            }
        },
        computed: {
            ...mapGetters({
                hasRole: 'auth/hasRole',
                auctions: 'callForBids/auctions',
                callForBids: 'callForBids/callForBids',
                currentUser: 'auth/user'
            }),
            overviewTitle() {
                return this.hasRole('Administrator')||this.hasRole('Einkäufer')||this.hasRole('Administrator') ? this.$t(`auction_view.overviewtitle_overview`) : this.$t(`auction_view.overviewtitle_informations`);
            },
            mainTitle() {
                return this.isAuction ? this.$t(`auction_view.maintitle_auction`) : this.$t(`auction_view.maintitle_tender`);
            },
            graphOptions() {
                let options = [{
                    value: 0,
                    text: this.$t(`auction_view.graph_title`)
                }];

                if(this.auction.items) {
                    this.auction.items.forEach(i => {
                        options.push({
                            value: i.id,
                            text: i.name
                        })
                    });
                }

                return options;
            },
            userTableFields() {
                return [
                    {
                        key: 'id',
                        label: 'Id'
                    },
                    {
                        key: 'company',
                        label: this.$t(`user.label_company`),
                        sortable: true
                    },
                    {
                        key: 'name',
                        label: this.$t(`user.label_username`),
                        sortable: true
                    },
                    {
                        key: 'email',
                        label: this.$t(`user.label_email`),
                        sortable: true
                    },
                    {
                        key: 'phone',
                        label: this.$t(`user.label_phone`),
                        sortable: true
                    },
                    {
                        key: 'role.name',
                        label: this.$t(`user.label_role`),
                        sortable: true
                    },
                    {
                        key: 'accepted_terms_of_service',
                        label: this.$t(`user.label_accountactivation`),
                        sortable: true
                    },
                    {
                        key: 'lksg_scope',
                        label: 'LkSG'
                    }
                ];
            },
            bidAuctionItemFields() {
                let fields = [
                    {
                        key: 'order',
                        label: this.$t(`auction_view.label_no`)
                    },
                    {
                        key: 'name',
                        label: this.$t(`auction_view.label_name`)
                    },
                    {
                        key: 'description',
                        label: this.$t(`auction_view.label_spec`)
                    },
                    {
                        key: 'amount',
                        label: this.$t(`auction_view.label_amount`)
                    },
                    {
                        key: 'last_bid',
                        label: this.$t(`auction_view.label_lastbid`)
                    },
                    {
                        key: 'sum',
                        label: this.$t(`auction_view.label_sum`)
                    }
                ];

                if(this.auction.lowest_bid_visible)
                {
                    fields.push({
                        key: 'lowest_bid',
                        label: this.$t(`auction_view.label_lowestbid`)
                    });
                }
                if(this.auction.ranking_visible) {
                    fields.push({
                        key: 'ranking',
                        label: this.$t(`auction_view.label_ranking`)
                    });
                }

                fields.push({
                    key: 'new_bid',
                    label: this.$t(`auction_view.label_newbid`)
                });

                return fields;
            },
            currentRankingValue() {
                return this.currentRanking > -1 ? this.currentRanking : '---';
            },
            fileTableFields() {
                return [
                    {
                        key: 'name',
                        label: this.$t(`auction_view.label_filename`)
                    },
                    {
                        key: 'description',
                        label: this.$t(`auction_view.label_filedescription`)
                    },
                    {
                        key: 'filename',
                        label: this.$t(`auction_view.label_file`)
                    },
                    {
                        key: 'size',
                        label: this.$t(`auction_view.label_filesize`)
                    },
                    {
                        key: 'actions',
                        label: this.$t(`auction_view.label_actions`)
                    }
                ];
            },
            uploadTableFields() {
                return [
                    {
                        key: 'filename',
                        label: this.$t(`auction_view.label_file`)
                    },
                    {
                        key: 'description',
                        label: this.$t(`auction_view.label_filedescription`)
                    },
                    {
                        key: 'size',
                        label: this.$t(`auction_view.label_filesize`)
                    },
                    {
                        key: 'created_at',
                        label: this.$t(`auction_view.label_uploaddate`)
                    },
                    {
                        key: 'actions',
                        label: this.$t(`auction_view.label_actions`)
                    }
                ];
            },
            uploadedTableFields() {
                return [
                    {
                        key: 'user.name',
                        label: this.$t(`auction_view.label_username`),
                        sortable: true
                    },
                    {
                        key: 'user.company',
                        label: this.$t(`auction_view.label_company`),
                        sortable: true
                    },
                    {
                        key: 'filename',
                        label: this.$t(`auction_view.label_file`),
                        sortable: true
                    },
                    {
                        key: 'description',
                        label: this.$t(`auction_view.label_filedescription`),
                        sortable: true
                    },
                    {
                        key: 'size',
                        label: this.$t(`auction_view.label_filesize`),
                        sortable: true
                    },
                    {
                        key: 'created_at',
                        label: this.$t(`auction_view.label_uploaddate`),
                        sortable: true
                    },
                    {
                        key: 'actions',
                        label: this.$t(`auction_view.label_actions`)
                    }
                ];
            },
            restrictedFileTableFields() {
                return [
                    {
                        key: 'name',
                        label: this.$t(`auction_view.label_filename`)
                    },
                     {
                        key: 'description',
                        label: this.$t(`auction_view.label_filedescription`)
                    },
                    {
                        key: 'filename',
                        label: this.$t(`auction_view.label_file`)
                    },
                    {
                        key: 'size',
                        label: this.$t(`auction_view.label_filesize`)
                    },
                    {
                        key: 'actions',
                        label: this.$t(`auction_view.label_actions`)
                    }
                ];
            },
            auctionItemFields() {
                return [
                    {
                        key: 'order',
                        label: this.$t(`auction_view.label_no`)
                    },
                    {
                        key: 'name',
                        label: this.$t(`auction_view.label_name`)
                    },
                    {
                        key: 'amount',
                        label: this.$t(`auction_view.label_amount`),
                        formatter: value => { return numberHelper.toFormattedStringWithThousands(value); }
                    },
                    {
                        key: 'unit',
                        label: this.$t(`auction_view.label_unit`)
                    },
                ];
            },
            auctionItemFieldsManagement() {
                return [
                    {
                        key: 'order',
                        label: this.$t(`auction_view.label_no`)
                    },
                    {
                        key: 'name',
                        label: this.$t(`auction_view.label_name`)
                    },
                    {
                        key: 'description',
                        label: this.$t(`auction_view.label_spec`)
                    },
                    {
                        key: 'amount',
                        label: this.$t(`auction_view.label_amount`),
                        formatter: value => { return numberHelper.toFormattedStringWithThousands(value); }
                    },
                    {
                        key: 'unit',
                        label: this.$t(`auction_view.label_unit`)
                    }
                ];
            },
            bidConfirmationTable() {
                return [
                    {
                        key: 'order',
                        label: this.$t(`auction_view.confirm_bid.label_no`)
                    },
                    {
                        key: 'name',
                        label: this.$t(`auction_view.confirm_bid.label_item`)
                    },
                    {
                        key: 'bid',
                        label: this.$t(`auction_view.confirm_bid.label_bid`)
                    },
                    {
                        key: 'sum',
                        label: this.$t(`auction_view.confirm_bid.label_sum`)
                    },
                ];
            },
            bidOverViewFields() {
                let fields = this.bidOverViewData.columnDefinitions;
                fields[0].label = this.$t(`bidtable.label_no`);
                fields[1].label = this.$t(`bidtable.label_name`);
                fields[2].label = this.$t(`bidtable.label_amount`);
                fields[3].label = this.$t(`bidtable.label_unit`);
                fields[4].label = this.$t(`bidtable.label_actual_value`);
                return fields;
            },
            editType() {
            return this.isAuction ? this.$t("cfbcreate.auction") : this.$t("cfbcreate.tender");
        },
        },
        created() {
            this.isAuction = this.$route.path.indexOf('/app/auktionen') > -1;

            if(this.$route.params.id) {
                this.$store.commit('loading/setLoading', true);
                callForBidsService.fetch(this.$route.params.id)
                    .then(response => {
                        this.auction = response.data;

                        if(response.data.success === false) {
                            this.hasError = true;
                            this.errorMessage = response.data.message;
                            this.$store.commit('loading/setLoading', false);
                            this.doneLoading = true;
                            return;
                        }
                        
                        callForBidsService.listBidStatus(this.auction.id)
                            .then(response => {
                                this.bidstatus = response.data;
                            });

                        this.pollUpdatedInfos();

                        if(this.hasRole('Administrator')||this.hasRole('Berater')||this.hasRole('Einkäufer')||this.hasRole('Erweiterter Einkäufer')) {
                            this.fetchAssignedUsers();
                            callForBidsService.getQuestionnaireAnswers(this.$route.params.id)
                            .then(response => {
                                this.questionnaireData = response.data;
                            });

                            if(this.isAuction) {
                                return callForBidsService.bidOverview(this.$route.params.id)
                                    .then(response => {
                                        this.bidOverViewData = response;
                                        this.bidOverViewData.columnDefinitions[2].formatter = this.formatThousands;
                                        this.bidOverViewData.columnDefinitions[4].formatter = this.formatThousands;
                                        return callForBidsService.getHistory(this.$route.params.id)
                                            .then(response => {
                                                response.data.datasets.forEach(dataset => {
                                                    dataset.data.map(d => d.x = this.$moment(d.x))
                                                })

                                                this.bidHistoryData = response.data;
                                            });
                                    });
                            }

                            return this.getUserBids(this.$route.params.id)
                                .then(response => {
                                    this.doneLoading = true;
                                    this.$store.commit('loading/setLoading', false);
                                });
                        } else {
                            QuestionnaireService.fetchForMeByAuction(this.$route.params.id)
                                .then(response => {
                                    this.supplierQuestionnaires = response.data;

                                    return QuestionnaireService.fetchMyAnswers()
                                        .then(answers => {
                                        this.$store.commit('loading/setLoading', false);
                                        this.supplierAnswers = answers.data;
                                        this.doneLoading = true;
                                        });
                                });

                            this.doneLoading = true;
                            this.$store.commit('loading/setLoading', false);
                        }
                    });
            }
        },
        validations() {
            let validations = {
                bidAuctionItems: {
                    $each: {
                        new_bid: {
                            checkNumber: (value) => { return numberHelper.isValidNumber(value, this.auction.precision); }
                        }
                    }
                }
            };
            return validations;
        },
        beforeDestroy () {
            if(this.polling) {
                clearInterval(this.polling)
            }
        },
        methods: {
            getBidStatus(user) {
                return this.bidstatus.filter(item => item.user.id==user)[0];
            },
            updateBidStatus() {
                this.$store.commit('loading/setLoading', true);
                callForBidsService.updateBidStatus(this.bidstatus[0])
                    .then(response => {
                        this.$store.commit('loading/setLoading', false);
                        
                        this.$notify({
                                group: 'global',
                                title: 'Gebotsstatus',
                                type: 'success',
                                text: 'Gebotsstatus erfolgreich aktualisiert'
                            });
                    })
                    .catch(error => {
                            this.$store.commit('loading/setLoading', false);

                            this.$notify({
                                group: 'global',
                                title: 'Gebotsstatus',
                                type: 'error',
                                text: error.message
                            });
                    });
            },
            downloadFile(item) {
                this.$store.commit('loading/setLoading', true);
                callForBidsService.downloadFile(item)
                    .then(response => {
                        this.$store.commit('loading/setLoading', false);
                        const url = window.URL.createObjectURL(new Blob([response], {type: item.mimetype}));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', item.filename);
                        document.body.appendChild(link);
                        link.click();
                        link.parentElement.removeChild(link);
                    });
            },
            downloadUploadedFile(item) {
                this.$store.commit('loading/setLoading', true);
                callForBidsService.downloadUploadedFile(item)
                    .then(response => {
                        this.$store.commit('loading/setLoading', false);
                        const url = window.URL.createObjectURL(new Blob([response], {type: item.mimetype}));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', item.filename);
                        document.body.appendChild(link);
                        link.click();
                        link.parentElement.removeChild(link);
                    });
            },
            showFileForm() {
                this.currentFile = {
                    description: ''
                };
                this.$refs.fileDialog.show();
            },
            showBidConfirmation() {
                this.$v.$touch();

                if(this.$v.$error) {
                    return;
                }

                this.bidRequestItems = [];

                this.bidAuctionItems.forEach(item => {
                    if(item.new_bid !== undefined && item.new_bid !== NaN && item.new_bid !== null && item.new_bid !== '') {
                        var copiedItem = objectHelper.copy(item);

                        copiedItem.bid = numberHelper.toCents(copiedItem.new_bid, this.auction.precision);
                        if (copiedItem.bid != NaN) {
                            copiedItem.sum = copiedItem.bid * (copiedItem.multiplicator ? copiedItem.multiplicator : 1);
                            this.bidRequestItems.push(copiedItem);
                        }
                    }
                });

                if(this.bidRequestItems.length === 0) {
                    this.$notify({
                        group: 'global',
                        title: this.$t("auction_view.notify_bidrequest_title"),
                        type: 'warning',
                        text: this.$t("auction_view.notify_bidrequest_message"),
                    });

                    return;
                }

                this.$refs.confirmBidDialog.show();
            },
            editLink(item) {
            return this.isAuction ? '/app/auktionen/edit/' + item.id : '/app/ausschreibung/edit/' + item.id;
            },
            viewLink(item) {
                return this.isAuction ? '/app/auktionen/view/' + item.id : '/app/ausschreibung/view/' + item.id;
            },
            onFileUploadSubmit() {
                this.$store.commit('loading/setLoading', true);

                callForBidsService.newUpload(this.auction.id ,this.currentFile)
                    .then(response => {

                        this.$notify({
                                group: 'global',
                                title: this.$t("auction_view.notify_fileuploadsuccess_title"),
                                type: 'success',
                                text: this.$t("auction_view.notify_fileuploadsuccess_message")
                            });

                        this.$refs.fileDialog.hide();

                        this.currentFile = {
                            description: ''
                        };

                        callForBidsService.listUploads(this.auction.id)
                            .then(response => {
                                this.auction.uploads = response.data;
                                this.$store.commit('loading/setLoading', false);
                            });
                    })
                    .catch(error => {
                            this.$store.commit('loading/setLoading', false);

                            this.$notify({
                                group: 'global',
                                title: this.$t("auction_view.notify_fileuploaderror_title"),
                                type: 'error',
                                text: error.message
                            });
                    });
            },
            bid() {
                var requestPayload = [];
                this.bidRequestItems.forEach(item => {
                    requestPayload.push({
                        id: item.id,
                        bid: item.bid,
                    });
                });

                this.$store.commit('loading/setLoading', true);
                callForBidsService.bid(this.auction.id, requestPayload)
                    .then(response => {
                        if(response.data.success) {
                            this.$notify({
                                group: 'global',
                                title: this.$t("auction_view.notify_bidsuccess_title"),
                                type: 'success',
                                text: this.$t("auction_view.notify_bidsuccess_message")
                            });
                            this.$refs.confirmBidDialog.hide();

                            return this.getUserBids(this.auction.id);
                        }else {
                            this.$notify({
                                group: 'global',
                                title: this.$t("auction_view.notify_biderror_title"),
                                type: 'error',
                                text: response.data.message,
                                duration: 10000
                            });
                            this.$refs.confirmBidDialog.hide();
                            this.$store.commit('loading/setLoading', false);
                        }
                    })
                    .catch(error => {
                        this.$notify({
                                group: 'global',
                                title: this.$t("auction_view.notify_biderror_title"),
                                type: 'error',
                                text: error.message,
                                duration: 10000
                            });
                            this.$refs.confirmBidDialog.hide();
                            this.$store.commit('loading/setLoading', false);
                    });
            },
            showUpload() {
                this.$refs.bidUploadModal.showModal();
            },
            confirmUploadedBids(newBids) {
                this.bidRequestItems = newBids;
                this.$refs.confirmBidDialog.show();
            },
            getUserBids(auctionId) {
                this.$store.commit('loading/setLoading', true);
                return callForBidsService.getUserBids(auctionId)
                    .then(response => {
                        this.bidAuctionItems = response.bids;
                        this.currentRanking = response.ranking;
                        this.$store.commit('loading/setLoading', false);
                    });
            },
            getItemSum(item) {
                if(item.lastBid === null) {
                    return "---"
                }

                var multiplicator = item.multiplicator ? item.multiplicator : 1;

                return this.formatCurrencyFromInteger(item.lastBid * multiplicator);
            },
            getSumOfItems() {
                var sum = 0;

                this.bidAuctionItems.forEach(item => {
                    var multiplicator = item.multiplicator ? item.multiplicator : 1;
                    sum += item.lastBid * multiplicator;
                });

                return this.formatCurrencyFromInteger(sum)
            },
            getSumOfActiveBid() {
                var sum = 0;

                this.bidRequestItems.forEach(item => {
                    sum += item.sum;
                });

                return this.formatCurrencyFromInteger(sum)
            },
            getSumOfLowestBid() {
                var sum = 0;

                this.bidAuctionItems.forEach(item => {
                    var multiplicator = item.multiplicator ? item.multiplicator : 1;
                    sum += item.bid * multiplicator;
                });

                return this.formatCurrencyFromInteger(sum);
            },
            getLastBid(item) {
                if(item.lastBid === null) {
                    return "---"
                }

                return this.formatCurrencyFromInteger(item.lastBid);
            },
            getLowestBid(item) {
                if(item.bid === null) {
                    return "---";
                }

                return this.formatCurrencyFromInteger(item.bid);
            },
            getRanking(item) {
                if(item.ranking === 0) {
                    return "---";
                }

                return item.ranking;
            },
            formatCurrencyFromInteger(value) {

                var precision = this.auction.precision;

                return numberHelper.toCurrency(value, precision);
            },
            toCurrency(value) {
            return numberHelper.toCurrency(value, this.auction.precision);
            },
            formatThousands(value) {
                return numberHelper.toFormattedStringWithThousands(value);
            },
            updateUserBids() {
                this.getUserBids(this.auction.id);
            },
            isUserColumn(columnName) {
                return columnName.indexOf('user_') > -1;
            },
            getRankForColumn(columnName) {
                for(var column in this.bidOverViewData.rankData) {
                    if(column === columnName) {
                        var rank = this.bidOverViewData.rankData[columnName];

                        return rank > 0 ? rank : '---';
                    }
                }

                return '';
            },
            getSumForColumn(columnName) {
                for(var column in this.bidOverViewData.sumData) {
                    if(column === columnName) {
                        var sum = this.bidOverViewData.sumData[columnName];
                        return sum !== 0 ? sum : '---';
                    }
                }

                return '';
            },
            newlineToBr: function (value) {
                return value.replace(/(?:\r\n|\r|\n)/g, '<br />');
            },
            deleteUpload(upload) {
                this.selectedUpload = upload;
                this.$refs.confirmUploadDeletion.show();
            },
            deleteUploadedFile() {
                this.$store.commit('loading/setLoading', true);

                callForBidsService.deleteUpload(this.selectedUpload.id)
                    .then(response => {
                        this.$refs.confirmUploadDeletion.hide();
                        this.selectedUpload = {};

                        if(response.data.success) {
                            this.$notify({
                                group: 'global',
                                title: this.$t("auction_view.notify_deleteuploadsuccess_title"),
                                type: 'success',
                                text: this.$t("auction_view.notify_deleteuploadsuccess_message")
                            });

                            callForBidsService.listUploads(this.auction.id)
                                .then(response => {
                                    this.auction.uploads = response.data;
                                    this.$store.commit('loading/setLoading', false);
                                });
                        }else {
                            this.$notify({
                                group: 'global',
                                title: this.$t("auction_view.notify_deleteuploaderror_title"),
                                type: 'error',
                                text: response.data.message
                            });
                        }
                    });
            },
            reloadGraph(value) {
                this.$store.commit('loading/setLoading', true);

                if(value === 0) {
                    callForBidsService.getHistory(this.auction.id)
                        .then(response => {
                            response.data.datasets.forEach(dataset => {
                                dataset.data.map(d => d.x = this.$moment(d.x))
                            })

                            this.bidHistoryData = response.data;
                            this.doneLoading = true;

                            this.$store.commit('loading/setLoading', false);
                        });
                }else {
                    callForBidsService.getHistoryForItem(value)
                        .then(response => {
                            response.data.datasets.forEach(dataset => {
                                dataset.data.map(d => d.x = this.$moment(d.x))
                            })

                            this.bidHistoryData = response.data;
                            this.doneLoading = true;

                            this.$store.commit('loading/setLoading', false);
                        });
                }
            },
            fetchAssignedUsers() {
                return callForBidsService.getAssignedUsers(this.auction.id)
                    .then(response => {
                        this.users = response.data;
                    });
            },
            pollUpdatedInfos() {
                this.polling = setInterval(() => {
                    callForBidsService.getUpdatedInfos(this.auction.id)
                        .then(response => {
                            let endDate = this.$moment(response.end_date);
                            this.auction.end_date = response.end_date;
                    });
                }, 3000);
            }

        },
        watch: {
            '$route': function() {
                this.isAuction = this.$route.path.indexOf('/app/auktionen') > -1;
            }
        },
        filters: {

        }
    };
    </script>
