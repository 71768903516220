import axios from 'axios';

export default {
    setEnabledForUser(userId, yearId, enabled) {
        const payload = {
            user_id: userId,
            year_id: yearId,
            enabled: enabled,
        }
        return axios.post(window.applicationSettings.apiEndpoint + '/risk/analysis/user/setenabled', payload)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    setEnabledForQuestionnaire(userId, yearId, questionnaireId, enabled) {
        const payload = {
            user_id: userId,
            year_id: yearId,
            questionnaire_id: questionnaireId,
            enabled: enabled,
        }
        return axios.post(window.applicationSettings.apiEndpoint + '/risk/analysis/setenabled', payload)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    fetchAnalysis(userId, yearId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/risk/analysis/' + userId + '/' + yearId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    postAnalysis(payload) {
        return axios.post(window.applicationSettings.apiEndpoint + '/risk/analysis', payload)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
}