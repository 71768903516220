<template>
    <b-card title="Risiken eigener Geschäftsbereich" class="mb-3 shadow" v-if="!hasRole('Lieferant')"">
        <b-button v-b-toggle.help_risks pill size="sm" variant="outline-secondary" class="info-button"><em>i</em></b-button>
        <b-sidebar right id="help_risks" :title="$t(`help.info`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">
            <div class="px-3 py-2">
                <b-list-group>
                    <b-list-group-item>Zu jedem Score-Wert besteht eine detaillierte Aufgliederung der Antworten und der zugehörigen Scores.</b-list-group-item>
                    <b-list-group-item>Innerhalb dieser Detailansicht sind grau formatierte Fragen entweder dem Benutzer noch nicht angezeigt worden oder wurden in Abhängigkeit der gegebenen Antworten übersprungen.</b-list-group-item>
                    <b-list-group-item></b-list-group-item>
                    <b-list-group-item></b-list-group-item>
                </b-list-group>
            </div>
        </b-sidebar>

        <b-row>
            <b-col>
                <b-form-group v-model="filterYear" label="Jahr" class="mb-3" v-slot="{ ariaDescribedby }">
                    <b-form-select v-model="filterYear" :aria-describedby="ariaDescribedby" class="mt-1" @change="fetchScores">
                        <option v-for="year in years" :key="year.id" :value="year.id">{{ year.year }}</option>
                    </b-form-select>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group v-model="filterAuction" label="Aktion" class="mb-3" v-slot="{ ariaDescribedby }">
                    <b-form-select v-model="filterAuction" :aria-describedby="ariaDescribedby" class="mt-1" @change="fetchScores">
                        <option v-for="auction in auctions" :key="auction.id" :value="auction.id">{{ auction.title }}</option>
                    </b-form-select>
                </b-form-group>
            </b-col>
        </b-row>

        <div class="table-scrollable">
            <b-table class="mb-2" striped hover :items="filteredSupplierRisks" :fields="fields" responsive="sm" sticky-header="800px">
                <template #cell(completed)="data">
                    <div style="width: 10rem;">
                        <b-progress :max="data.item.questionnaire_score.count">
                            <b-progress-bar :value="data.item.questionnaire_score.count_completed">
                            <span>{{ data.item.questionnaire_score.count_completed }}</span>
                            </b-progress-bar>
                        </b-progress>
                    </div>
                </template>
                <template #cell(analysis_enabled)="data">
                    <b-checkbox v-model="data.item.analysis_enabled" @change="analysisEnabledForUserChange(data.item)" inline class="ml-2 mr-0"></b-checkbox>
                </template>
                <template #cell()="data">
                    <template v-if="data.field.key.startsWith('q')">
                        <b-form-checkbox v-if="data.value.relevant_for_analysis" v-model="data.value.analysis_enabled" @change="analysisEnabledForQuestionnaireChange(data.value)" v-b-tooltip.hover.left="'konkrete Risikoanalyse notwendig'" inline class="ml-1 mr-2">
                            <b-link @click="showQuestionnaireModal(data.value.user_id, data.value.questionnaire_id)">{{ data.value.score_value }}</b-link>
                            <b-icon icon="exclamation-triangle" v-if="data.value.score != 'vollständig / complete'" v-b-tooltip.hover.right="'Fragebogen unvollständig'" scale="1.2"></b-icon>
                        </b-form-checkbox>
                    </template>
                    <template v-else>{{ data.value }}</template>
                </template>
            </b-table>
        </div>
        <questionnaireScorePopup ref="questionnaireScorePopup" />
    </b-card>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import riskService from '../../../../../services/Riskmanagement/RiskService';
import riskAnalysisService from '../../../../../services/Riskmanagement/RiskanalysisService';
import callForBidsService from '../../../../../services/CallForBidsService';
import questionnaireService from '../../../../../services/QuestionnaireService';
import questionnaireScorePopup from './QuestionnaireScorePopup';

export default {
    name: 'supplierscoretab',
    components: {questionnaireScorePopup},
    props: [],
    data() {
        return {
            filterScope: 'eigener Geschäftsbereich',
            filterAuction: null,
            filterYear: null,
            supplierrisks: [],
            auctions: [],
            scores: [],
            questionnaires: [],
            selectedYear: null,
            score_high: 20,
            score_middle: 10,
            score_low: 5,
            score_max: 50,
        }
    },
    created() {
        this.fetchYears();
        this.fetchSupplierRisks();
        this.fetchAuctions();
    },
    methods: {
        ...mapActions({
            fetchYears: 'years/fetch',
        }),
        fetchSupplierRisks() {
            this.$store.commit('loading/setLoading', true);
                riskService.listSupplierRisks()
                    .then(response => {
                        this.supplierrisks = response;
                        this.$store.commit('loading/setLoading', false);
                    }).catch(error => {
                        this.$store.commit('loading/setLoading', false);
                    });
        },
        fetchAuctions() {
            this.$store.commit('loading/setLoading', true);
                callForBidsService.list(false, false, false)
                    .then(response => {
                        this.auctions = response.data;
                        this.$store.commit('loading/setLoading', false);
                    }).catch(error => {
                        this.$store.commit('loading/setLoading', false);
                    });
        },
        fetchScores(event) {
            this.questionnaires = [];
            this.scores = [];
            if (!this.filterYear || !this.filterAuction) { return; }
            this.$store.commit('loading/setLoading', true);
            questionnaireService.fetchForAuctions(this.filterAuction)
                .then(response => {
                    this.questionnaires = response.data;
                });
            riskService.listQuestionnaireRisks(this.filterYear, this.filterAuction)
                    .then(response => {
                        this.scores = response;
                        this.$store.commit('loading/setLoading', false);
                    }).catch(error => {
                        this.$store.commit('loading/setLoading', false);
                    });

            let users = this.supplierrisks.filter(item => {
                const filter1Match = !this.filterScope || item.lksg_scope === this.filterScope;
                const filter2Match = !!this.filterYear && this.filterYear === item.year.id;
                return filter1Match && filter2Match;
            }).map(item => item.user_id);
            this.scores = this.scores.filter(item => users.includes(item.id));
        },
        newlineToBr: function (value) {
                return value.replace(/(?:\r\n|\r|\n)/g, '<br />');
        },
        score_median(id) {
            let liste = this.scores
                .filter(score => {return score.questionnaire_id==id})
                .map(item => {return item.score_value})
                .toSorted((a, b) => a - b);
            if (liste.length == 0) { return 0; }
            return (liste[Math.floor(liste.length / 2)]);
        },
        tdClass(value, key, item) {
            if (key.startsWith('q')) {
                if(value.score_value == 0) {
                    return 'bg-success';
                } else if (value.score_value < this.score_median(value.questionnaire_id)) {
                    return 'bg-warning';
                } else {
                    return 'bg-danger';
                }
            }
            return '';
        },
        showQuestionnaireModal(user_id, questionnaire_id) {
            const displayName = (this.supplierrisks.filter(item => item.id == user_id)[0]).display_name;
            const popupUser = {id: user_id, display_name: displayName};
            const popupQuestionnaire = this.questionnaires.filter(item => item.id == questionnaire_id)[0];
            this.$refs.questionnaireScorePopup.showModal(popupUser, popupQuestionnaire);
        },
        analysisEnabledForUserChange(item) {
            riskAnalysisService.setEnabledForUser(item.id, item.year.id, item.analysis_enabled)
        },
        analysisEnabledForQuestionnaireChange(score) {
            riskAnalysisService.setEnabledForQuestionnaire(score.user_id, score.year_id, score.questionnaire_id, score.analysis_enabled)
        },
    },
    computed: {
        ...mapGetters({
            hasRole: 'auth/hasRole',
            years: 'years/years'
        }),
        filteredAuctions() {
            return this.auctions.filter(item => {
                return !!this.filterYear && this.filterYear === item.year.year
            });
        },
        filteredSupplierRisks() {
            let result = this.supplierrisks.filter(item => {
                const filter1Match = !this.filterScope || item.lksg_scope === this.filterScope;
                const filter2Match = !!this.filterYear && this.filterYear === item.year.id;
                return filter1Match && filter2Match;
            }).map(item => {
                let itemWithScore = item;
                this.questionnaires.forEach(questionnaire => {
                    let foundScore = this.scores.filter(score => {return score.questionnaire_id==questionnaire.id && score.user_id==item.id});
                    if (foundScore.length == 1) {
                        itemWithScore['q'+questionnaire.id] = foundScore[0];
                        itemWithScore['q'+questionnaire.id].relevant_for_analysis = questionnaire.relevant_for_analysis;
                    }
                });
                return itemWithScore;
            });
            return result;
        },
        fields() {
            let fieldList = [
                //{ key: 'ID', sortable: true },
                { key: 'year.year',                             label: 'Jahr',                                      },
                { key: 'analysis_enabled',                      label: 'Konkrete Risikoanalyse'                     },
                //{ key: '1',                                     label: 'Lieferanten Nr.',                         },
                //{ key: '2',                                     label: 'ABC-Level',                             sortable: true },
                { key: 'display_name',                          label: 'Gesellschaft',                             class: "text-nowrap", stickyColumn: true },
                /*{ key: 'lksg_scope',                            label: 'LkSG-Scope',                            sortable: true },
                { key: 'country.country_iso_3166',              label: 'ISO Code',                              sortable: true },
                { key: 'country.country_name',                  label: 'Land',                                  sortable: true },
                { key: 'country_score.score',                   label: 'Länderrisiko',                          sortable: true },
                { key: 'sector.sector_code_nace',               label: 'NACE',                                  sortable: true },
                { key: 'sector.sector_name',                    label: 'Branche',                               sortable: true },
                { key: 'sector_score.score',                    label: 'Branchen-Risiko',                        sortable: true },
                { key: '3',                                     label: 'Auftrags-volumen p.a.',                  sortable: true },
                { key: '4',                                     label: 'Volumen-spezifisches Risiko',            sortable: true },
                { key: '5',                                     label: 'Ergebnis initiale Risiko-einschätzung',  sortable: true },
//              { key: 'questionnaire_score.score_value',       label: 'Fragebogen Score',                      sortable: true },
                { key: 'completed',                             label: 'Bearbeitungsstatus',                    sortable: true },*/
            ];
            this.questionnaires.filter(item => !!this.filterYear && this.filterYear === item.year_id)
                .forEach(element => {
                    fieldList.push({key: 'q' + element.id, label: element.name,  tdClass: this.tdClass} );
                });
            return fieldList;
        },
    },
}
</script>