<template>
    <div v-if="$i18n.locale=='de'">
        <div class="terms-of-use">  
            <ol>
                <li>
                    <strong>Geltungsbereich</strong>
                    <ol>
                        <li>Diese Nutzungsbedingungen gelten in ihrer bei Vertragsschluss gültigen Fassung zwischen der RSM Ebner Stolz Management Consultants GmbH („RSM Ebner Stolz“) und dem Kunden für die Nutzung der über das RSM ESupply LkSG Tool („RSM ESupply LkSG Tool“) über das Internet bereitgestellten Dienste und Leistungen („Services“).</li>
                        <li>Die Allgemeinen Geschäftsbedingungen von RSM Ebner Stolz finden auf das RSM ESupply LkSG Tool ergänzende Anwendung. Im Fall von Widersprüchen zwischen diesen Nutzungsbedingungen und den Allgemeinen Geschäftsbedingungen gehen die Nutzungsbedingungen den Allgemeinen Geschäftsbedingungen im Umfang der Widersprüche vor. </li>
                        <li>Die über das RSM ESupply LkSG Tool angebotenen Services richten sich ausschließlich an Unternehmen in Ausübung ihrer gewerblichen oder selbstständigen Tätigkeit und juristischen Personen des öffentlichen Rechts. </li>
                        <li>Abweichende, entgegenstehende oder ergänzende Allgemeine Geschäftsbedingungen des Kunden werden, selbst bei Kenntnis von RSM Ebner Stolz, nicht Vertragsbestandteil, es sei denn, ihrer Geltung wird durch RSM Ebner Stolz ausdrücklich schriftlich zugestimmt.</li>
                    </ol>
                </li>
                <li>
                    <strong>Diensteangebot</strong>
                    <ol>
                        <li>OnlinePortal RSM ESupply LkSG Tool - <br />
                            Das RSM ESupply LkSG Tool ist eine digitale Plattform die Unternehmen bei der Umsetzung der von dem Lieferkettensorgfaltspflichtengesetz („LkSG“) vorgegebenen Sorgfaltspflichten unterstützen kann. Das RSM ESupply LkSG Tool dient dazu, relevante Daten zur Durchführung einer angemessenen abstrakten sowie konkreten Risikoanalyse im eigenen Unternehmen und ggf. mit ihm verbundenen Unternehmen im Sinne des § 2 Abs. 6 LkSG, in Bezug auf den eigenen Geschäftsbereich sowie bei den unmittelbaren Zulieferern und gegebenenfalls mittelbaren Zulieferern systematisch zu erfassen und auszuwerten. Hierzu können Kunden für ihre Geschäftspartner insbesondere individuelle Fragebögen entwickeln, die im Anschluss von den Geschäftspartnern auszufüllen sind. Mit den erhobenen Daten soll eine möglichst valide Aussage hinsichtlich eventueller menschenrechtlicher und umweltbezogener Risiken im Sinne des LkSG getroffen werden. Hierzu hat RSM Ebner Stolz in dem RSM ESupply LkSG Tool eine allgemeine Berechnungsmethodik für die Einstufung der Risiken hinterlegt. Diese Berechnungsmethodik muss gemeinsam mit dem Kunden individuell auf das Unternehmen des Kunden angepasst werden. Das RSM ESupply LkSG Tool bietet zudem die Möglichkeit der fortlaufenden Kommunikation zwischen den registrierten Unternehmen. So kann der Kunde über das RSM ESupply LkSG Tool bspw. seinen Verhaltenskodex für Geschäftspartner an seine Geschäftspartner versenden, Unterschriften einfordern oder Schulungskonzepte abwickeln.    </li>
                        <li>Das RSM ESupply LkSG Tool wird als webbasierte Anwendung auf einer zentralen ITInfrastruktur mit einer zentralen Datenbank betrieben, auf die der Kunde Zugriff über den InternetBrowser erhält.</li>
                        <li>RSM Ebner Stolz ist berechtigt, die Leistungen durch Dritte als Unterauftragnehmer erbringen zu lassen.</li>
                        <li>Übergabepunkt für die vertraglichen Leistungen von RSM Ebner Stolz ist der Routerausgang der von RSM Ebner Stolz genutzten Rechenzentren zum Internet. Die Anbindung des Kunden an das Internet, die Aufrechterhaltung der Netzverbindung sowie die Beschaffung und Bereitstellung der auf Seiten des Kunden erforderlichen Infrastruktur, insbesondere Hard und Software ist nicht Gegenstand der durch RSM Ebner Stolz erbrachten Leistungen. </li>
                        <li>Das RSM ESupply LkSG Tool ersetzt in keinem Fall die Einzelfallberatung durch ge-schulte Rechtsanwälte oder sonstige Berater. Das RSM ESupply LkSG Tool dient le-diglich zur abstrakten Unterstützung von Unternehmen bei der Umsetzung der Sorgfaltspflichten nach dem LkSG. Die Arbeitsergebnisse des RSM ESupply LkSG Tools sind von jedem Unternehmen eigenverantwortlich auf Richtigkeit, Vollstän-digkeit und Vereinbarkeit mit dem LkSG hin zu überprüfen.</li>
                    </ol>
                </li>
                <li>
                    <strong>Verfügbarkeit</strong>
                    <ol>
                        <li>3.1	RSM Ebner Stolz stellt eine möglichst unterbrechungsfreie Nutzbarkeit des RSM E-Supply LkSG Tools sicher. RSM Ebner Stolz übernimmt keine Haftung dafür, dass der Zugang oder die Nutzung des RSM ESupply LkSG Tools nicht durch Wartungsarbei-ten, Weiterentwicklungen oder anderweitig durch Störungen kurzzeitig unterbro-chen oder beeinträchtigt wird. RSM Ebner Stolz trifft technische Vorkehrungen und Sicherheitsmaßnahmen, damit sämtliche gespeicherte Daten im Rechenzentrum dauerhaft dokumentiert und archiviert werden.</li>
                        <li>RSM Ebner Stolz wird geplante Wartungs und Ausfallzeiten dem Kunden rechtzeitig ankündigen.</li>
                    </ol>
                </li>
                <li>
                    <strong>Registrierung</strong>
                    <ol>
                        <li>Um die in dem RSM ESupply LkSG Tool verfügbaren Services nutzen zu können, muss sich der Kunde elektronisch registrieren. Zur Registrierung und Nutzung des RSM ESupply LkSG Tools sind ausschließlich gewerbliche Kunden berechtigt. Eine Nutzung durch Verbraucher im Sinne von § 13 des Bürgerlichen Gesetzbuches ist ausgeschlossen. Der Kunde sichert die Unternehmereigenschaft im Rahmen des Registrierungsprozesses zu. RSM Ebner Stolz behält sich vor, von dem Kunden einen ausreichenden Nachweis für die Unternehmereigenschaft zu verlangen. Die für den Nachweis erforderlichen Daten sind vom Kunden vollständig und wahrheitsgemäß anzugeben.</li>
                        <li>Der Kunde hat bei der Registrierung die im Registrierungsprozess geforderten Angaben wie Adress und Kontaktdaten („Registrierungsdaten“) zu machen. Pflichtfelder sind mit einem „*“ versehen. Der Kunde ist verpflichtet, die Registrierungsdaten vollständig und korrekt anzugeben. Es ist dem Kunden insbesondere untersagt sich als eine andere Person oder Unternehmen auszugeben, oder einen Namen oder eine Firma zu verwenden, zu dessen Nutzung er nicht berechtigt ist.</li>
                        <li>Der Kunde ist verpflichtet, die Registrierungsdaten stets aktuell zu halten und etwaige Veränderungen unverzüglich zu korrigieren. </li>
                        <li>Mit der Absendung der Registrierungsdaten (entweder per Registrierungslink oder über individuelle Zugangsdaten) unterbreitet RSM Ebner Stolz dem Kunden ein Angebot zum Abschluss eines Nutzungsverhältnisses auf Grundlage dieser Nutzungsbedingungen. Der Vertragsschluss erfolgt nach Bestätigung der Nutzungsbedingungen und nach Übermittlung der vollständigen Registrierungsdaten durch den Kunden. Mit dieser Bestätigung erfolgt die sofortige Freischaltung. Ab Freischaltung ist der Kunde zur Nutzung des RSM ESupply LkSG Tools im Rahmen dieser Nutzungsbedingungen berechtigt. Der Vertragstext wird von RSM Ebner Stolz gespeichert, ist für den Kunden jedoch nicht abrufbar.</li>
                        <li>Der Account nebst Zugangsdaten ist ohne schriftliche Zustimmung von RSM Ebner Stolz nicht übertragbar. </li>
                        <li>Die Zugangsdaten einschließlich des Passworts sind vom Kunden geheim zu halten und unbefugten Dritten nicht zugänglich zu machen. Der Kunde ist für die Sicherheit des Passworts selbst verantwortlich. Er sorgt dafür, dass das Passwort nur den innerhalb des Unternehmens befugten Person zugänglich ist. Die Verantwortung für die unter dem LoginNamen in Verbindung mit dem Passwort vorgenommenen Handlungen trägt der Kunde.</li>
                        <li>Steht zu befürchten, dass unbefugte Dritte von den Zugangsdaten Kenntnis erlangt haben oder erlangen werden, ist RSM Ebner Stolz unverzüglich zu informieren. Der Kunde hat die Möglichkeit das individuelle Passwort eigenständig zu verändern.</li>
                    </ol>
                </li>
                <li>
                    <strong>Nutzungsrecht des Kunden</strong>
                    <ol>
                        <li>RSM Ebner Stolz räumt dem Kunden ein auf die Dauer des Nutzungsverhältnisses beschränktes, einfaches, grundsätzlich nicht unterlizenzierbares und nicht übertragbares Recht ein, die über das RSM ESupply LkSG Tool verfügbaren Services im Rahmen der vertraglich vereinbarten Funktionalitäten für eigene Unternehmenszwecke zu nutzen. Soweit während der Vertragslaufzeit neue Versionen, Updates oder Upgrades durch RSM Ebner Stolz bereitgestellt werden, gilt hierfür ebenfalls das vorgenannte Nutzungsrecht.</li>
                        <li>Der Kunde ist zum Zwecke der für die Risikoanalysen erforderlichen Datensammlungen berechtigt, seinen unmittelbaren und mittelbaren Geschäftspartnern ein auf die Dauer des Nutzungsverhältnisses beschränktes, einfaches, nicht unterlizenzierbares und nicht übertragbares Recht einzuräumen, die über das RSM ESupply LkSG Tool individuell eingestellten Fragebögen auszufüllen und die Kommunikation mit dem Kunden zu führen. Der Kunde ist berechtigt, den Zulieferern zu diesem Zweck einen Registrierungslink zuzusenden. Der Kunde hat vertraglich sicherzustellen, dass seine Geschäftspartner das RSM ESupply LkSG Tool nicht über den in diesem Absatz beschriebenen Zweck nutzen. Die Nutzung des RSM ESupply LkSG Tools durch die Geschäftspartner müssen rein zweckgebunden und im Einklang mit diesen Nutzungsbedingungen von RSM Ebener Stolz sein. In der Anlage 1 zu diesen Nutzungsbedingungen sind Muster-Nutzungsbedingungen für das Rechtsverhältnis zwischen dem Kunden und seinem Geschäftspartner beigefügt. Diese Muster-Nutzungsbedingungen enthalten Mindestanforderungen, die der Kunde an seine Geschäftspartner weitergeben sollte. RSM Ebner Stolz übernimmt keine Haftung für das bereitgestellte Muster, so dass der Kunde die Muster-Nutzungsbedingungen auf Geeignetheit zu prüfen hat.</li>
                        <li>Über die Zwecke dieser Nutzungsbedingungen hinaus ist der Kunde nicht berechtigt die Services zu nutzen, zu vervielfältigen, herunterzuladen oder Dritten zugänglich zu machen.</li>
                    </ol>
                </li>
                <li>
                    <strong>Mitwirkungspflichten</strong>
                    <ol>
                        <li>Für eine Nutzung des RSM ESupply LkSG Tools ist mindestens ein Endgerät, eine Internetverbindung sowie die Registrierung in dem RSM ESupply LkSG Tool erforderlich. Fehlt einer dieser Bestandteile, ist keine Nutzung des RSM ESupply LkSG Tools möglich. </li>
                        <li>Der Kunde ist für die Schaffung der in seinem Verantwortungsbereich liegenden notwendigen technischen Voraussetzungen (insbesondere Hardware und Internetzugang) zur vertragsgemäßen Nutzung des RSM ESupply LkSG Tool selbst verantwortlich. </li>
                    </ol>
                </li>
                <li>
                    <strong>Vergütung</strong>
                    <ol>
                        <li>Die Registrierung für das RSM ESupply LkSG Tool und die anschließende Nutzung erfolgen entgeltlich. Hierzu wird eine gesonderte Vereinbarung getroffen.</li>
                    </ol>
                </li>
                <li>
                    <strong>Einstellen eigener Inhalte</strong>
                    <ol>
                        <li>Der Kunde kann in dem RSM ESupply LkSG Tool sein Benutzerkonto unter Beachtung der nachfolgenden Regelungen individualisieren und dabei Bilddateien wie z.B. das eigene Firmenlogo (nachfolgend zusammen „eigene Inhalte“) in dem eigenen Profil hinterlegen oder auch die Darstellung der gesamten Website durch RSM Ebner Stolz individualisieren lassen. Der Kunde kann überdies insbesondere eigene Dokumente hochladen (bspw. Firmenpräsentationen, Verhaltenskodizes, Allgemeine Einkaufsbedingungen, Zertifikate, o.ä.) und Lieferanten bereitstellen. </li>
                        <li>Mit dem Einstellen von eigenen Inhalten räumt der Kunde RSM Ebner Stolz jeweils ein unentgeltliches, zeitlich unbefristetes und übertragbares Nutzungsrecht an den eigenen Inhalten ein, insbesondere zur Speicherung der eigenen Inhalte auf den Servern von RSM Ebner Stolz und zur Bearbeitung und Vervielfältigung, soweit dies für die Erbringung der Services nach Maßgabe dieser Nutzungsbedingungen erforderlich ist oder durch den Kunden veranlasst wurde.</li>
                        <li>Der Kunde garantiert gegenüber RSM Ebner Stolz, dass er alleiniger Inhaber sämtlicher Rechte an den eingestellten eigenen Inhalten oder anderweitig berechtigt ist (z.B. durch eine wirksame Erlaubnis des Rechteinhabers), die eigenen Inhalte in dem RSM ESupply LkSG Tool einzustellen und die Nutzungs und Verwertungsrechte wie zuvor dargestellt zu gewähren. Der Kunde verpflichtet sich im Übrigen keine eigenen Inhalte einzustellen, die gegen geltendes Recht verstoßen.</li>
                        <li>Der Kunde ist verpflichtet RSM Ebner Stolz von allen Kosten, Ansprüchen und Nachteilen freizustellen, die Dritte wegen der Verletzung ihrer Rechte durch die eigenen Inhalte des Kunden oder wegen einer Gesetzesverletzung des Kunden gegenüber RSM Ebner Stolz geltend machen. Dies gilt nicht, wenn der Kunde die Verletzung der Rechte Dritter nicht zu vertreten hat.</li>
                    </ol>
                </li>
                <li>
                    <strong>Kündigung, Löschfrist, Datenexport, Sperrung des Zugangs</strong>
                    <ol>
                        <li>Die Parteien können das Nutzungsverhältnis jederzeit ohne Einhaltung einer Frist mit sofortiger Wirkung kündigen. Der Kunde kann das Nutzungsverhältnis kündigen, in dem er eine EMail an andre.grotstabel@ebnerstolz.de sendet. RSM Ebner Stolz wird den Eingang der Kündigung per EMail bestätigen und dem Kunden mitteilen, dass mit der Kündigung die im RSM ESupply LkSG Tool enthaltenden Daten des Kunden innerhalb 90 Tagen gelöscht werden („Löschfrist“). Dem Kunden werden die im RSM ESupply LkSG Tool enthalten Daten während der Löschfrist elektronisch bereitgestellt. Der Kunde kann diese Daten innerhalb der Löschfrist exportieren. Nach Ablauf der Löschfrist, wird das Kundenkonto des Kunden im RSM ESupply LkSG Tool unwiderruflich gelöscht.</li>
                        <li>RSM Ebner Stolz kann den Zugang des Kunden zu dem RSM ESupply LkSG Tool insgesamt oder zu einzelnen Teilbereichen sperren oder einschränken, wenn konkrete Anhaltspunkte vorliegen, dass der Kunde gegen diese Nutzungsbedingungen und/oder geltendes Recht verstoßen hat, oder wenn RSM Ebner Stolz ein sonstiges berechtigtes Interesse an der Sperrung hat. Bei der Entscheidung über eine Sperrung werden die berechtigten Interessen des Kunden angemessen berücksichtigt. </li>
                        <li>Im Falle der vorübergehenden Sperrung wird die Zugangsberechtigung des Kunden gesperrt und der Kunde hierüber per EMail benachrichtigt. Nach Ablauf der Sperrzeit oder dem endgültigen Wegfall des Sperrgrundes wird die Zugangsberechtigung reaktiviert und der Kunde wird hierüber per EMail benachrichtigt. Eine dauerhaft gesperrte Zugangsberechtigung kann nicht wiederhergestellt werden. Dauerhaft gesperrte Kunden sind von der Teilnahme an dem RSM ESupply LkSG Tool dauerhaft ausgeschlossen und dürfen sich ohne vorherige schriftliche Zustimmung von RSM Ebner Stolz nicht erneut für das RSM ESupply LkSG Tool registrieren.</li>
                    </ol>
                </li>
                <li>
                    <strong>Haftungsbeschränkung</strong>
                    <ol>
                        <li>Eine Haftung für die Vollständigkeit, Richtigkeit und Aktualität der auf dem Portal verfügbaren Inhalte wird von RSM Ebner Stolz nicht übernommen. RSM Ebner Stolz übernimmt darüber hinaus keine Haftung für die Richtigkeit, Vollständigkeit und Aktualität der in dem RSM ESupply LkSG Tool ausgeführten Arbeitsergebnisse. RSM Ebner Stolz übernimmt darüber hinaus keine Haftung dafür, dass mit den Arbeitsergebnissen des RSM ESupply LkSG Tools die Anforderungen des LkSG an das Unternehmen im konkreten Einzelfall erfüllt werden.</li>
                        <li>RSM Ebner Stolz haftet nur bei Vorsatz und grober Fahrlässigkeit von RSM Ebner Stolz oder ihrer Erfüllungs oder Verrichtungsgehilfen sowie im Falle einer Verletzung wesentlicher Vertragspflichten, d. h. solcher Pflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglichen und auf deren Einhaltung der Kunde regelmäßig vertrauen darf. Bei der Verletzung einer wesentlichen Vertragspflicht, welche nicht auf Vorsatz oder grober Fahrlässigkeit beruht, beschränkt sich die Haftung von RSM Ebner Stolz auf den bei Vertragsschluss vorhersehbaren und typischen Schaden.</li>
                        <li>Die vorstehenden Bestimmungen zur Haftungsbeschränkung gelten nicht bei einer Verletzung des Lebens, des Körpers und der Gesundheit und für die Haftung nach zwingenden gesetzlichen Vorschriften wie z.B. denen des Produkthaftungsgesetzes.</li>
                        <li>Soweit die Schadensersatzhaftung RSM Ebner Stolz gegenüber ausgeschlossen oder eingeschränkt ist, gilt dies auch im Hinblick auf die persönliche Schadenersatzhaftung der Angestellten, gesetzlichen Vertreter und Erfüllungsgehilfen von RSM Ebner Stolz.</li>
                        <li>Der Kunde ist für die Einhaltung gesetzlicher Aufbewahrungspflichten allein verantwortlich. Eine Haftung von RSM Ebner Stolz ist insoweit ausgeschlossen.</li>
                    </ol>
                </li>
                <li>
                    <strong>Datenschutz</strong>
                    <ol>
                        <li>Es gelten die RSM Ebner Stolz Datenschutzhinweise, abrufbar unter https://www.ebnerstolz.de/de/datenschutzerklaerung43912.html.</li>
                    </ol>
                </li>
                <li>
                    <strong>Änderungen dieser Nutzungsbedingungen und der Services</strong>
                    <ol>
                        <li>RSM Ebner Stolz behält sich das Recht vor, diese Nutzungsbedingungen jederzeit mit Wirksamkeit auch innerhalb der bestehenden Vertragsverhältnisse zu ändern. RSM Ebner Stolz wird dem Kunden mindestens 30 Kalendertage vor dem geplanten Inkrafttreten einer Änderung der Nutzungsbedingungen in Kenntnis setzen. </li>
                        <li>Sofern der Kunde nicht innerhalb von 30 Tagen ab Zugang der Mitteilung widerspricht, gelten die Änderungen mit Fristablauf als wirksam vereinbart. Im Falle eines Widerspruchs wird das Nutzungsverhältnis zu den bisherigen Bedingungen fortgesetzt. RSM Ebner Stolz behält sich das Recht vor, im Falle eines Widerspruchs das Nutzungsverhältnis zum nächstmöglichen Zeitpunkt zu beenden. In der Änderungsmitteilung wird der Kunde auf sein Widerspruchsrecht und auf die Folgen hingewiesen.</li>
                        <li>RSM Ebner Stolz behält sich zudem das Recht vor, die unentgeltlich bereitgestellten Services jederzeit zu ändern, neue Services unentgeltlich oder entgeltlich verfügbar zu machen und die Bereitstellung unentgeltlicher Services einzustellen. RSM Ebner Stolz wird hierbei auf die berechtigten Interessen des Kunden Rücksicht nehmen. Ohne aktive Zustimmung des Kunden werden keine Zusatzkosten für bislang unentgeltliche Dienste anfallen.</li>
                    </ol>
                </li>
                <li>
                    <strong>Schlussbestimmungen</strong>
                    <ol>
                        <li>Individuelle Änderungen und Ergänzungen dieser Nutzungsbedingungen bedürfen der Schriftform. Dies gilt auch für Änderungen des Schriftformerfordernisses. Das Schriftformerfordernis wird durch EMail nicht gewahrt.</li>
                        <li>Es gilt deutsches Recht unter Ausschluss des deutschen Internationalen Privatrechts und des UNKaufrechts.</li>
                        <li>Ausschließlicher Gerichtsstand ist der Sitz von RSM Ebner Stolz in Köln. RSM Ebner Stolz ist auch berechtigt, am Sitz des Kunden zu klagen.</li>
                        <li>Sollte eine Bestimmung dieser Nutzungsbedingungen unwirksam sein oder werden, so bleibt die Wirksamkeit der übrigen Bestimmungen hiervon unberührt. Die Parteien sind in einem solchen Falle verpflichtet, an der Schaffung von Bestimmungen mitzuwirken, durch die ein der unwirksamen Bestimmung wirtschaftlich möglichst nahekommendes Ergebnis rechtswirksam erzielt wird. Das Vorstehende gilt für die Schließung etwaiger Vertragslücken entsprechend.</li>
                    </ol>
                </li>
            </ol>
            <p>Stand: 12.04.2024</p>
        </div>
    </div>
    
    </template>
    <script>
    export default {
        name: 'termsOfServiceIntern',
    };
    </script>
    