<template>
    <div class="card" style="border-width: 0px" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')">
        <div class="card-header px-0" style="background-color: #f4f5fa">
            {{ $t("risk.header_suppliers") }}
        </div>
        <div class="card-body px-0" style="background-color: #f4f5fa">
            <b-tabs>
                <b-tab title="Abstrakte RA unm. Zulieferer" active>
                    <SupplierRiskTab />
                </b-tab>
                <b-tab title="Konkrete RA unm. Zulieferer">
                    <SupplierScoreTab />
                </b-tab>
                <b-tab title="Abstrakte RA m. Zulieferer">
                    <SupplierRiskTabIndirect />
                </b-tab>
                <b-tab title="Konkrete RA m. Zulieferer">
                </b-tab>
            </b-tabs>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import SupplierRiskTab from './modules/SupplierRiskTab';
import SupplierScoreTab from './modules/SupplierScoreTab';
import SupplierRiskTabIndirect from './modules/SupplierRiskTabIndirect';
import { dateHelper } from '../../../mixins/dateHelper';

export default {
    name: 'supplychainact',
    components: { 
        SupplierRiskTab, 
        SupplierScoreTab,
        SupplierRiskTabIndirect,
    },
    mixins: [dateHelper],
    data() {
        return {
            stickyHeader: true,
        }
    },
    created() {
    },
    methods: {

    },
    computed: {
        ...mapGetters({
            hasRole: 'auth/hasRole',
        }),
    }
};
</script>
