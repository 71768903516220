<template>
     <b-card-group deck>
        <b-card class="mb-3 shadow" title="Einzelbewertung Lieferant druchführen">
            <b-form-group label="Jahr" label-for="year1">
                <b-form-select v-model="year1" text-field="year" value-field="id" :options="years" :state="!$v.year1.$error ? null : false"></b-form-select>
            </b-form-group>
            <b-form-group label="Benutzer" label-for="user1">
                <vue-multiselect id="user1" v-model="user1" :options="suppliers" :searchable="true" :close-on-select="true" :show-labels="false" 
                    track-by="id" label="display_name" :allow-empty="false" placeholder="Benutzer auswählen, tippen für Suche"
                    :class="{ is_invalid: !!$v.user1.$error }">
                </vue-multiselect>
            </b-form-group>
            <b-button class="button-margin" variant="primary" @click.prevent="executeCalculateOne">Bewertung durchführen</b-button>
        </b-card>

        <b-card class="mb-3 shadow" title="Jahresbewertung durchführen">
            <b-form-group label="Jahr" label-for="year2">
                <b-form-select v-model="year2" text-field="year" value-field="id" :options="years" :state="!$v.year2.$error ? null : false"></b-form-select>
            </b-form-group>
            <b-button class="button-margin" variant="primary" @click.prevent="showConfirmation">Bewertung durchführen</b-button>
        </b-card>

        <b-modal ref="confirmationModal" hide-footer title="Bestätigung">
            <div class="d-block text-center">
                <p>Sie überschreiben alle vorhandenen Bewertungen</p>
            </div>
            <b-button class="button-margin mt-3" variant="outline-danger" block @click="executeCalculateAll"><b-icon icon="trash" />Überschreiben</b-button>
        </b-modal>

    </b-card-group>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import userService from '../../../../../services/UserService';
    import riskService from '../../../../../services/Riskmanagement/RiskService';
    import { helpers, required } from 'vuelidate/lib/validators';

    export default {
        name: 'calculate',
        components: {},
        props: [],
        data() {
            return {
                year1: null,
                user1: null,
                year2: null,
                suppliers: [],
            }
        },
        validations: {
            year1: {
                required
            },
            user1: {
                required
            },
            year2: {
                required
            },
        },
        created() {
            this.$store.commit('loading/setLoading', true);
            return userService.listSuppliers()
                .then(data => {
                    this.suppliers = data.data;
                    this.$store.commit('loading/setLoading', false);
                });
        },
        methods: {
            ...mapActions({
            }),
            executeCalculateOne() {
                this.$v.year1.$touch();
                this.$v.user1.$touch();

                if (this.$v.year1.$invalid || this.$v.user1.$invalid) {
                    this.$notify({
                        group: 'global',
                        title: 'Eingabe ungültig',
                        type: 'error',
                        text: 'Bewertung nicht durchgeführt'
                    });
                    return;
                }

                riskService.evaluateOne(this.year1, this.user1.id)
                    .then(response => {
                        this.$notify({
                            group: 'global',
                            title: 'Bewertung durchgeführt',
                            type: 'success',
                            text: 'Bewertung durchgeführt'
                        });
                    })
                    .catch(error => {
                        this.$notify({
                            group: 'global',
                            title: 'Fehler',
                            type: 'error',
                            text: 'Bewertung nicht durchgeführt'
                        });
                    });
            },
            executeCalculateAll() {
                this.$v.year2.$touch();

                if (this.$v.year2.$invalid) {
                    this.$notify({
                        group: 'global',
                        title: 'Eingabe ungültig',
                        type: 'error',
                        text: 'Bewertung nicht durchgeführt'
                    });
                    return;
                }

                this.$refs.confirmationModal.hide();
                this.$store.commit('loading/setLoading', true);
                riskService.evaluateAll(this.year2)
                    .then(response => {
                        this.$notify({
                            group: 'global',
                            title: 'Bewertung durchgeführt',
                            type: 'success',
                            text: 'Bewertung durchgeführt'
                        });
                        this.$store.commit('loading/setLoading', false);
                    })
                    .catch(error => {
                        this.$notify({
                            group: 'global',
                            title: 'Fehler',
                            type: 'error',
                            text: 'Bewertung nicht durchgeführt'
                        });
                        this.$store.commit('loading/setLoading', false);
                    });
            },
            showConfirmation() {
                this.$v.year2.$touch();
                if (this.$v.year2.$invalid) {
                    this.$notify({
                        group: 'global',
                        title: 'Eingabe ungültig',
                        type: 'error',
                        text: 'Bewertung nicht durchgeführt'
                    });
                    return;
                }
                this.$refs.confirmationModal.show();
            }
        },
        computed: {
            ...mapGetters({
                hasRole: 'auth/hasRole',
                years: 'years/years'
            }),
        }
    }
</script>