<template>
    <b-modal size="xl" ref="questionnairePopupModal" ok-only no-close-on-backdrop scrollable :title="getTitle">
        <b-container>
            <b-table striped hover :items="questions" :fields="fields" responsive="sm">
                <template #cell(question)="data">
                    <span :style="(data.item.relevant == 1) ? 'color: black' : 'color: #aaaaaa'">
                       {{ data.value }}
                    </span>
                </template>
            </b-table>
        </b-container>
    </b-modal>
</template>
    <script>
    import riskService from '../../../../../services/Riskmanagement/RiskService';
    export default {
        name: 'questionnaire-popup',
        data() {
            return {
                user: {display_name: ""},
                questionnaire: {name: ""},
                questions: [],
                fields: [
                    { key: 'question', label: 'Fragen' },
                    { key: 'answer', label: 'Antworten' },
                    { key: 'score', label: 'Scoring' },
            ],
            }
        },
        methods: {
            showModal(user, questionnaire) {
                this.$store.commit('loading/setLoading', true);
                this.user = user;
                this.questionnaire = questionnaire;
                riskService.listQuestionRisks(this.user.id, this.questionnaire.id)
                    .then(response => {
                        this.questions = response;
                        this.$store.commit('loading/setLoading', false);
                    }).catch(error => {
                        this.$store.commit('loading/setLoading', false);
                    });
                this.$refs.questionnairePopupModal.show();
            },
            hideModal() {
                this.$refs.questionnairePopupModal.hide();
            },
        },
        computed: {
            getTitle() {
                return this.questionnaire?.name + ' / ' + this.user?.display_name;
            }
        }
    }
    </script>
