<template>
    <nav class="navbar sticky-top mb-0">
        <div class="container nav-container-settings">
            
            <span class="d-flex" style="cursor: pointer;" @click="toggleactive()" aria-controls="sidebarMenu">
                <b-iconstack font-scale="3">
                    <b-icon stacked icon="dash" shift-v="1.5" shift-h="-1.7" scale="1"></b-icon>
                    <b-icon stacked icon="dash" shift-v="-2" shift-h="-2.1" scale="0.9"></b-icon>
                </b-iconstack>
            </span>

            <router-link to="/" class="d-inline-block navbar-brand ml-auto mb-0">
                <img src="images/diils_Logo_schwarz.png" height="100" class="d-inline-block align-right" alt="">
            </router-link>

<!-- 
            <a class="btn btn-primary" role="button" data-toggle="collapse" href="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Menü">
                <span class="navbar-toggler-icon"></span>
            </a>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                
                <ul class="navbar-nav mr-auto" v-if="!loggedIn">
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="consultingDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            diils
                        </a>
                        <div class="dropdown-menu" aria-labelledby="consultingDropdown">
                            <router-link to="/einkauf" class="dropdown-item">{{ $t("nav.einkauf") }}</router-link>
                            <router-link to="/kostenmanagement" class="dropdown-item">{{ $t("nav.kostenmanagement") }}</router-link>
                            <router-link to="/team" class="dropdown-item">{{ $t("nav.team") }}</router-link>
                        </div>
                    </li>
                    <li class="nav-item">
                        <router-link to="/auktionen" class="nav-link">{{ $t("nav.auktionen") }}</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/ausschreibungen" class="nav-link">{{ $t("nav.ausschreibungen") }}</router-link>
                    </li>
                </ul>
-->
                <!-- Right Side Of Navbar -->
                <ul class="navbar-nav">
                    <!-- Authentication Links -->
                    <template v-if="!loggedIn">
                        <li class="nav-item">
                            <router-link to="/login" class="nav-link">{{ $t("nav.login") }}</router-link>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import localeHelper from '../helpers/localeHelper';
export default {
    data() {
        return {}
    },
    computed: {
        ...mapGetters({
            hasRole: 'auth/hasRole',
            loggedIn: 'auth/loggedIn',
            currentUser: 'auth/user',
        }),
        loggedInUserEmail: function() {
            return this.currentUser ? this.currentUser.email : '';
        },
        supportedLocales() {
            return localeHelper.supportedLocales;
        }
    },
    methods: {
        ...mapActions({
            logout: 'auth/logout'
        }),
        toggleactive(){
            document.getElementById('sidebarMenu').classList.toggle('active');
            document.getElementById('app').classList.toggle('active');
        },
        toggleactive2(){
            document.getElementById('sidebarMenu2').classList.toggle('active');
        },
        smToggle() {
            var screenWidth = window.innerWidth;
            if (screenWidth < 768) {
                document.getElementById("sidebarMenu").classList.toggle('active');
            }
        },
        logoutCurrentUser () {
            this.logout().then(() => {
                return this.$router.push({path:'/login'});
            });
        }
    }
}
</script>
