import store from '../../store/store';

export default (to, from, next) => {
	if (!store.getters['auth/loggedIn']) {
		store.dispatch('auth/logout');
		return next({path: '/login', query: { fromPath: to.fullPath } });		
	}

	if(!store.getters['auth/user'].accepted_dsgvo || !store.getters['auth/user'].accepted_terms_of_service || !store.getters['auth/user'].proof_of_entrepreneurial_status ) {
		return next('/app/dashboard');
	}

	return next();
}