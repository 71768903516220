<template>
    <div class="home">
        <b-img src="images/welt.png" fluid />
        <span v-html="$t('presentation.team')" />
        <b-card-group deck>
            <b-card title="RSM Ebner Stolz Management Consultants GmbH">
                <p class="card-text">
                    <strong>André Grotstabel</strong> 
                    <br />
                    Senior Manager
                    <br/><br/>
                    <a href="mailto: andre.grotstabel@ebnerstolz.de">andre.grotstabel@ebnerstolz.de</a><br/>
                    <br />
                </p>
            </b-card>
            <b-card title="RSM Ebner Stolz">
                <p class="card-text">
                    <strong>Hannah Dragon</strong>
                    <br />Consultant
                    <br/><br/>
                    <a href="mailto: hannah.dragon@ebnerstolz.de">hannah.dragon@ebnerstolz.de</a><br/>
                    <br />
                </p>
            </b-card>
        </b-card-group>
        <br/>
        <br/>
        <br/>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'team',
        data() {
            return {};
        },
        computed: {
            ...mapGetters({
                auth: 'auth/auth'
            })
        }
    }
</script>