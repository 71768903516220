<template>
<div class="card" style="border-width: 0px">
    <div class="card-header px-0" style="background-color: #f4f5fa">
        {{ $t("user.header_profile") }} <v-icon style="height: 12px" vertical-align="baseline" class="mb-1" name="chevron-right" /> {{ user.name }}
    </div>
    <div class="card-body px-0" style="background-color: #f4f5fa">
        <b-tabs>
            <b-tab :title="$t(`user.tab_editprofile`)" active>
                <b-card :title="$t(`user.card_editprofile`)" class="mb-3 shadow">
                    <b-form @submit.prevent="onSubmitUserInfo" v-show="doneLoading">
                        <b-row>
                            <b-col>
                                <b-form-group :label="$t(`user.name`)" label-for="user-name">
                                    <b-form-input id="user-name" type="text" v-model="user.name" :state="!$v.user.name.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.password`)" label-for="user-password">
                                        <b-form-input id="user-password" type="password" v-model="user.password" style="background-color: #fdfdfd"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.passwordrepeat`)" label-for="user-password-repeat">
                                        <b-form-input id="user-password-repeat" type="password" v-model="user.password_repeat" :state="!$v.user.password_repeat.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.contact`)" label-for="user-contact">
                                    <b-form-input id="user-contact" type="text" v-model="user.contact" :state="!$v.user.contact.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.phone`)" label-for="user-phone">
                                    <b-form-input id="user-phone" type="text" v-model="user.phone" :state="!$v.user.phone.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.email`)" label-for="user-email">
                                    <b-form-input id="user-email" type="email" v-model="user.email" :state="!$v.user.email.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.businessunit`)" label-for="user-business-unit">
                                    <b-form-input id="user-business-unit" type="text" v-model="user.business_unit" style="background-color: #fdfdfd"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.country_name`)" label-for="courtry">
                                    <vue-multiselect id="country" v-model="user.country" :options="countries" :searchable="true" :close-on-select="true" :show-labels="false" 
                                        track-by="id" label="country_name" :allow-empty="false" placeholder="Land auswählen, tippen für Suche"
                                        :class="!$v.user.country.$error ? '' : 'is-invalid'">
                                        <template slot="singleLabel" slot-scope="{ option }">{{ option.country_name }}</template>
                                    </vue-multiselect>
                                </b-form-group>

                                <b-form-group :label="$t(`user.sector_name`)" label-for="sector">
                                    <vue-multiselect id="sector" v-model="user.sector" :options="sectors" :searchable="true" :close-on-select="true" :show-labels="false" 
                                        track-by="id" label="sector_name" :allow-empty="false" placeholder="Branche auswählen, tippen für Suche"
                                        :class="!$v.user.country.$error ? '' : 'is-invalid'">
                                    </vue-multiselect>
                                </b-form-group>

                                <b-form-checkbox v-if="!hideAgreements" v-model="user.proof_of_entrepreneurial_status" class="terms-text">
                                    {{ $t("user.proof_of_entrepreneurial_status") }}
                                </b-form-checkbox>

                                <b-form-checkbox v-if="!hideAgreements && hasRole('Lieferant')" v-model="user.accepted_terms_of_service" class="terms-text">
                                    {{ $t("user.accept_termsofservice_1") }}<a href="#" @click.prevent="showTermsOfService">{{ $t("user.accept_termsofservice_2") }}</a>.
                                </b-form-checkbox>

                                <b-form-checkbox v-if="!hideAgreements && !hasRole('Lieferant')" v-model="user.accepted_terms_of_service" class="terms-text">
                                    {{ $t("user.accept_termsofservice_1") }}<a href="#" @click.prevent="showTermsOfServiceIntern">{{ $t("user.accept_termsofservice_2") }}</a>.
                                </b-form-checkbox>

                                <b-form-checkbox v-if="!hideAgreements" v-model="user.accepted_dsgvo" class="terms-text">
                                    {{ $t("user.accept_dsgvo") }}
                                </b-form-checkbox>

                            </b-col>
                            <b-col>
                                <b-form-group :label="$t(`user.company`)" label-for="user-company">
                                    <b-form-input id="user-company" type="text" v-model="user.company" :state="!$v.user.company.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.logo`)" label-for="user-logo">
                                    <template v-if="user.logo">
                                        <b-img :src="user.logo" class="preview-image"></b-img>
                                    </template>
                                    <p class="text-primary" v-if="!user.logo"><strong>{{ $t("user.not_available") }}</strong></p>
                                </b-form-group>

                                <b-form-group :label="$t(`user.logoupload`)" label-for="user-logo">
                                    <b-form-file id="user-logo" v-model="file" placeholder="Logo auswählen..." style="background-color: #fdfdfd"></b-form-file>
                                </b-form-group>

                                <b-form-group :label="$t(`user.address`)" label-for="user-address">
                                    <b-form-textarea id="user-address" type="text" v-model="user.address" :state="!$v.user.address.$error ? null : false" :no-resize="true" :rows="3" :max-rows="3" style="background-color: #fdfdfd"></b-form-textarea>
                                </b-form-group>

                                <b-form-group :label="$t(`user.employees`)" label-for="user-employees">
                                    <b-form-input id="user-employees" type="text" v-model="user.employee_count" :state="!$v.user.employee_count.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.sales`)" label-for="user-sales">
                                    <b-form-input id="user-sales" type="text" v-model="user.sales" style="background-color: #fdfdfd"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-button class="button-margin mt-4" type="submit" variant="primary"><b-icon icon="save" />{{ $t("user.btn_save") }}</b-button>
                    </b-form>
                </b-card>
            </b-tab>
        </b-tabs>
    </div>
    <b-modal id="termsOfService" ref="termsOfService" :title="$t('profile.termsofservicetitle')" size="xl" :cancelDisabled="true">
        <template slot="modal-footer">
            <b-button class="button-margin" @click="$refs.termsOfService.hide()"><b-icon icon="check2" />{{ $t("user.modal_btn_ok") }}</b-button>
        </template>
        <TermsOfServiceComponent />
    </b-modal>
    <b-modal id="termsOfService_intern" ref="termsOfService_intern" :title="$t('profile.termsofservicetitle')" size="xl" :cancelDisabled="true">
        <template slot="modal-footer">
            <b-button class="button-margin" @click="$refs.termsOfService_intern.hide()"><b-icon icon="check2" />{{ $t("user.modal_btn_ok") }}</b-button>
        </template>
        <TermsOfServiceInternComponent />
    </b-modal>
</div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import userService from '../../../../services/UserService';
import { helpers, required, email, sameAs, numeric } from 'vuelidate/lib/validators';
import TermsOfServiceComponent from '../../content/TermsOfService';
import TermsOfServiceInternComponent from '../../content/TermsOfServiceIntern';

export default {
    name: 'profile',
    components: {
        TermsOfServiceComponent,
        TermsOfServiceInternComponent,
    },
    data() {
        return {
            doneLoading: false,
            hideAgreements: false,
            user: {},
            file: {},
            selectedFragen: [],
            totalSum: 0
        }
    },
    created() {
        this.$store.commit('loading/setLoading', true);
        this.fetchCountries();
        this.fetchSectors();
        this.loadUserProfile();
    },
    validations: {
        user: {
            name:             {required},
            password_repeat:  {sameAsPassword: sameAs('password')},
            contact:          {required},
            phone:            {required},
            company:          {required},
            address:          {required},
            employee_count:   {required, numeric},
            email:            {required, email},
            country:          {required,},
            sector:           {required,},
        }
    },
    methods: {
        ...mapActions({
                fetchCountries: 'countries/fetch',
                fetchSectors: 'sectors/fetch'
            }),
        loadUserProfile() {
            this.$store.commit('loading/setLoading', true);

            return userService.fetchProfile()
                .then(userResponse => {
                    this.user = userResponse.data;
                    if(this.user.accepted_dsgvo && this.user.accepted_terms_of_service && this.user.proof_of_entrepreneurial_status) {
                        this.hideAgreements = true;
                    }
                    this.$store.commit('loading/setLoading', false);
                    this.doneLoading = true;
                })
        },
        onSubmitUserInfo() {
            this.$v.$touch();

            if(this.$v.$error) {
                return;
            }
            if (this.user.country) {
                this.user.country_id = this.user.country.id;
            }
            if (this.user.sector) {
                this.user.sector_id = this.user.sector.id;
            }

            userService.updateProfile(this.user, this.file)
                .then(response => {
                    if(response.data.success) {
                        this.$notify({
                            group: 'global',
                            title: this.$t("user.notify_profile_save"),
                            type: 'success',
                            text: this.$t("user.notify_profile_message")
                        });
                        // TODO: Does not work yet
                        this.loadUserProfile();
                    } else {
                        this.$notify({
                            group: 'global',
                            title: this.$t("user.notify_profile_error"),
                            type: 'error',
                            text: this.$t("user.notify_profile_errormessage")
                        });
                    }
                });
        },
        showTermsOfService() {
            this.$refs.termsOfService.show();
        },
        showTermsOfServiceIntern() {
            this.$refs.termsOfService_intern.show();
        },
    },
    computed: {
        ...mapGetters({
            hasRole: 'auth/hasRole',
            countries: 'countries/countries',
            sectors: 'sectors/sectors',
        }),
    }
};
</script>