<template>
    <div class="home">
        <b-img src="images/welt.png" fluid />
        <span v-html="$t('presentation.home')" />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'home',
        data() {
            return {};
        },
        computed: {
            ...mapGetters({
                auth: 'auth/auth'
            })
        }
    }
</script>