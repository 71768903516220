<template>
<div v-if="$i18n.locale=='de'">
    <div class="terms-of-use">
        <p>Allgemeine Nutzungsbedingungen der Pneuhage Management GmbH & Co. KG für die Nutzung des RSM ESupply LkSG Tools durch den Geschäftspartner</p>
        <br />
        <ol>
            <li>
                <strong>Geltungsbereich</strong>
                <ol>
                    <li>Diese Nutzungsbedingungen gelten in ihrer bei Vertragsschluss gültigen Fassung zwischen der Pneuhage Management GmbH & Co. KG („Pneuhage“) und dem Geschäftspartner  für die Nutzung der über das RSM ESupply LkSG Tool („RSM ESupply LkSG Tool“) über das Internet bereitgestellten Dienste und Leistungen („Services“).</li>
                    <li>Die Allgemeinen Geschäftsbedingungen von Pneuhage finden auf das RSM ESupply LkSG Tool ergänzende Anwendung. Im Fall von Widersprüchen zwischen diesen Nutzungsbedingungen und den Allgemeinen Geschäftsbedingungen gehen die Nutzungsbedingungen den Allgemeinen Geschäftsbedingungen im Umfang der Widersprüche vor. </li>
                    <li>Die über das RSM ESupply LkSG Tool angebotenen Services richten sich ausschließlich an Unternehmen in Ausübung ihrer gewerblichen oder selbstständigen Tätigkeit und juristischen Personen des öffentlichen Rechts. </li>
                    <li>Abweichende, entgegenstehende oder ergänzende Allgemeine Geschäftsbedingungen des Geschäftspartners werden, selbst bei Kenntnis von Pneuhage, nicht Vertragsbestandteil, es sei denn, ihrer Geltung wird durch Pneuhage ausdrücklich schriftlich zugestimmt.</li>
                </ol> 
            </li>
            <li>
                <strong>Diensteangebot</strong>
                <ol>
                    <li>Online-Portal RSM ESupply LkSG Tool</li>
                    <li>Das RSM ESupply LkSG Tool ist eine digitale Plattform die Unternehmen bei der Umsetzung der von dem Lieferkettensorgfaltspflichtengesetz („LkSG“) vorgegebenen Sorgfaltspflichten unterstützen kann. Das RSM ESupply LkSG Tool dient dazu, relevante Daten zur Durchführung einer angemessenen abstrakten sowie konkreten Risikoanalyse im eigenen Unternehmen und ggf. mit ihm verbundenen Unternehmen im Sinne des § 2 Abs. 6 LkSG, in Bezug auf den eigenen Geschäftsbereich sowie bei den unmittelbaren Zulieferern und gegebenenfalls mittelbaren Zulieferern systematisch zu erfassen und auszuwerten. Hierzu können Kunden für ihre Geschäftspartner insbesondere individuelle Fragebögen entwickeln, die im Anschluss von den Geschäftspartnern auszufüllen sind. Mit den erhobenen Daten soll eine möglichst valide Aussage hinsichtlich eventueller menschenrechtlicher und umweltbezogener Risiken im Sinne des LkSG getroffen werden. </li>
                    <li>Das RSM ESupply LkSG Tool bietet zudem die Möglichkeit der fortlaufenden Kommunikation zwischen den registrierten Unternehmen. So kann Pneuhage über das RSM ESupply LkSG Tool bspw. ihren Verhaltenskodex für Geschäftspartner an ihre Geschäftspartner versenden, Unterschriften einfordern oder Schulungskonzepte abwickeln.    </li>
                    <li>Das RSM ESupply LkSG Tool wird als webbasierte Anwendung auf einer zentralen IT-Infrastruktur mit einer zentralen Datenbank betrieben, auf die der Geschäftspartner Zugriff über den Internet-Browser erhält.</li>
                    <li>Übergabepunkt für die vertraglichen Leistungen von Pneuhage ist der Routerausgang der von Pneuhage genutzten Rechenzentren zum Internet. Die Anbindung des Geschäftspartners an das Internet, die Aufrechterhaltung der Netzverbindung sowie die Beschaffung und Bereitstellung der auf Seiten des Kunden erforderlichen Infrastruktur, insbesondere Hard- und Software ist nicht Gegenstand der durch Pneuhage erbrachten Leistungen. </li>
                </ol> 
            </li>
            <li>
                <strong>Verfügbarkeit</strong>
                <ol>
                    <li>Pneuhage stellt eine möglichst unterbrechungsfreie Nutzbarkeit des RSM ESupply LkSG Tools sicher. Pneuhage übernimmt keine Haftung dafür, dass der Zugang oder die Nutzung des RSM ESupply LkSG Tools nicht durch Wartungsarbeiten, Weiterentwicklungen oder anderweitig durch Störungen kurzzeitig unterbrochen oder beeinträchtigt wird. Pneuhage trifft technische Vorkehrungen und Sicherheitsmaßnahmen, damit sämtliche gespeicherte Daten im Rechenzentrum dauerhaft dokumentiert und archiviert werden. </li>
                    <li>Pneuhage wird sich bemühen geplante Wartungs- und Ausfallzeiten dem Kunden rechtzeitig anzukündigen.</li>
                </ol> 
            </li>
            <li>
                <strong>Registrierung</strong>  
                <ol>
                    <li>Um die in dem RSM ESupply LkSG Tool verfügbaren Services nutzen zu können, muss sich der Geschäftspartner elektronisch registrieren. Zur Registrierung und Nutzung des RSM ESupply LkSG Tools sind ausschließlich gewerbliche Kunden berechtigt. Eine Nutzung durch Verbraucher im Sinne von § 13 des Bürgerlichen Gesetzbuches ist ausgeschlossen. Der Geschäftspartner sichert die Unternehmereigenschaft im Rahmen des Registrierungsprozesses zu. Pneuhage behält sich vor, von dem Geschäftspartner einen ausreichenden Nachweis für die Unternehmereigenschaft zu verlangen. Die für den Nachweis erforderlichen Daten sind vom Geschäftspartner vollständig und wahrheitsgemäß anzugeben.</li>
                    <li>Der Geschäftspartner hat bei der Registrierung die im Registrierungsprozess geforderten Angaben wie Adress- und Kontaktdaten („Registrierungsdaten“) zu machen. Pflichtfelder sind mit einem „*“ versehen. Der Geschäftspartner ist verpflichtet, die Registrierungsdaten vollständig und korrekt anzugeben. Es ist dem Geschäftspartner insbesondere untersagt sich als eine andere Person oder Unternehmen auszugeben, oder einen Namen oder eine Firma zu verwenden, zu dessen Nutzung er nicht berechtigt ist.</li>
                    <li>Mit der Absendung der Registrierungsdaten (entweder per Registrierungslink oder über individuelle Zugangsdaten) unterbreitet Pneuhage dem Geschäftspartner ein Angebot zum Abschluss eines Nutzungsverhältnisses auf Grundlage dieser Nutzungsbedingungen. Der Vertragsschluss erfolgt nach Bestätigung der Nutzungsbedingungen und nach Übermittlung der vollständigen Registrierungsdaten durch den Geschäftspartner. Mit dieser Bestätigung erfolgt die sofortige Freischaltung. Ab Freischaltung ist der Geschäftspartner zur Nutzung des RSM ESupply LkSG Tools im Rahmen dieser Nutzungsbedingungen berechtigt. Der Vertragstext wird von Pneuhage gespeichert, ist für den Kunden jedoch nicht abrufbar.</li>
                    <li>Der Account nebst Zugangsdaten ist nicht übertragbar. </li>
                    <li>Die Zugangsdaten einschließlich des Passworts sind vom Geschäftspartner geheim zu halten und unbefugten Dritten nicht zugänglich zu machen. Der Geschäftspartner ist für die Sicherheit des Passworts selbst verantwortlich. Er sorgt dafür, dass das Passwort nur den innerhalb des Unternehmens befugten Person zugänglich ist. Die Verantwortung für die unter dem Login-Namen in Verbindung mit dem Passwort vorgenommenen Handlungen trägt der Geschäftspartner.</li>
                    <li>Steht zu befürchten, dass unbefugte Dritte von den Zugangsdaten Kenntnis erlangt haben oder erlangen werden, ist Pneuhage unverzüglich zu informieren. Der Geschäftspartner hat die Möglichkeit das individuelle Passwort eigenständig zu verändern.</li>
                </ol> 
            </li>
            <li>
                <strong>Nutzungsrecht des Geschäftspartners</strong>
                <ol>
                    <li>Pneuhage räumt dem Geschäftspartner ein auf die Dauer des Nutzungsverhältnisses beschränktes, einfaches, nicht unterlizenzierbares und nicht übertragbares Recht ein, die über das RSM ESupply LkSG Tool verfügbaren Services im Rahmen der vertraglich vereinbarten Funktionalitäten für eigene Unternehmenszwecke zu nutzen. Insbesondere kann der Geschäftspartner den von Pneuhage zur Verfügung gestellten Fragebogen beantworten und begleitende Dokumente und Informationen bereitstellen. Der Geschäftspartner hat seine Angaben wahrheitsgemäß und nicht irreführend zu tätigen.</li>
                    <li>Soweit während der Vertragslaufzeit neue Versionen, Updates oder Upgrades durch Pneuhage bereitgestellt werden, gilt hierfür ebenfalls das vorgenannte Nutzungsrecht.</li>
                    <li>Über die Zwecke dieser Nutzungsbedingungen hinaus ist der Geschäftspartner nicht berechtigt die Services zu nutzen, zu vervielfältigen, herunterzuladen oder Dritten zugänglich zu machen.</li>
                </ol> 
            </li>
            <li>
                <strong>Mitwirkungspflichten</strong>
                <ol>
                    <li>Für eine Nutzung des RSM ESupply LkSG Tools ist mindestens ein Endgerät, eine Internetverbindung sowie die Registrierung in dem RSM ESupply LkSG Tool erforderlich. Fehlt einer dieser Bestandteile, ist keine Nutzung des RSM ESupply LkSG Tools möglich. </li>
                    <li>Der Geschäftspartner ist für die Schaffung der in seinem Verantwortungsbereich liegenden notwendigen technischen Voraussetzungen (insbesondere Hardware und Internetzugang) zur vertragsgemäßen Nutzung des RSM ESupply LkSG Tool selbst verantwortlich. </li>
                </ol> 
            </li>
            <li>
                <strong>Vergütung</strong>
                <ol>
                    <li>Die Registrierung für das RSM ESupply LkSG Tool und die anschließende Nutzung erfolgen unentgeltlich. </li>
                </ol> 
            </li>
            <li>
                <strong>Einstellen eigener Inhalte</strong>
                <ol>
                    <li>Der Geschäftspartner kann in dem RSM ESupply LkSG Tool individuell erstellte Fragebögen beantworten und eigene Dokumente hochladen (bspw. Firmenpräsentationen, Verhaltenskodizes,  Zertifikate, o.ä.) und Pneuhage bereitstellen. </li>
                    <li>Mit dem Einstellen von eigenen Inhalten räumt der Geschäftspartner Pneuhage jeweils ein unentgeltliches, zeitlich unbefristetes und übertragbares Nutzungsrecht an den eigenen Inhalten ein, insbesondere zur Speicherung der eigenen Inhalte .</li>
                    <li>Der Geschäftspartner versichert gegenüber Pneuhage, dass er alleiniger Inhaber sämtlicher Rechte an den eingestellten eigenen Inhalten oder anderweitig berechtigt ist (z.B. durch eine wirksame Erlaubnis des Rechteinhabers), die eigenen Inhalte in dem RSM ESupply LkSG Tool einzustellen und die Nutzungs- und Verwertungsrechte wie zuvor dargestellt zu gewähren. Der Geschäftspartner verpflichtet sich im Übrigen keine eigenen Inhalte einzustellen, die gegen geltendes Recht verstoßen.</li>
                    <li>Der Geschäftspartner ist verpflichtet RSM Ebner Stolz von allen Kosten, Ansprüchen und Nachteilen freizustellen, die Dritte wegen der Verletzung ihrer Rechte durch die eigenen Inhalte des Geschäftspartners oder wegen einer Gesetzesverletzung des Geschäftspartners gegenüber Pneuhage geltend machen. Dies gilt nicht, wenn der Geschäftspartner die Verletzung der Rechte Dritter nicht zu vertreten hat.</li>
                </ol> 
            </li>
            <li>
                <strong>Kündigung, Löschfrist, Datenexport, Sperrung des Zugangs</strong>
                <ol>
                    <li>Die Parteien können das Nutzungsverhältnis jederzeit ohne Einhaltung einer Frist mit sofortiger Wirkung kündigen. Der Geschäftspartner kann das Nutzungsverhältnis kündigen, in dem er eine E-Mail an HGS@pneu.com sendet. Pneuhage wird den Eingang der Kündigung per E-Mail bestätigen. Mit der Kündigung wird Pneuhage den Zugang des Geschäftspartners zu dem RSM ESupply LkSG Tool sperren. Aufgrund von gesetzlichen Aufbewahrungsfristen, insbesondere nach dem deutschen Lieferkettensorgfaltspflichtengesetz, bleiben Daten und Informationen, die der Geschäftspartner gegenüber Pneuhage abgegeben hat, zur weiteren Nutzung durch Pneuhage in der Datenbank gespeichert.  </li>
                    <li>Pneuhage kann den Zugang des Geschäftspartners zu dem RSM ESupply LkSG Tool insgesamt oder zu einzelnen Teilbereichen sperren oder einschränken, wenn konkrete Anhaltspunkte vorliegen, dass der Geschäftspartner gegen diese Nutzungsbedingungen und/oder geltendes Recht verstoßen hat, oder wenn Pneuhage ein sonstiges berechtigtes Interesse an der Sperrung hat. Bei der Entscheidung über eine Sperrung werden die berechtigten Interessen des Geschäftspartners angemessen berücksichtigt. </li>
                    <li>Im Falle der vorübergehenden Sperrung wird die Zugangsberechtigung des Geschäftspartners gesperrt und der Geschäftspartner hierüber per E-Mail benachrichtigt. Nach Ablauf der Sperrzeit oder dem endgültigen Wegfall des Sperrgrundes wird die Zugangsberechtigung reaktiviert und der Geschäftspartner wird hierüber per E-Mail benachrichtigt. Eine dauerhaft gesperrte Zugangsberechtigung kann nicht wiederhergestellt werden. Dauerhaft gesperrte Geschäftspartner sind von der Teilnahme an dem RSM ESupply LkSG Tool dauerhaft ausgeschlossen und dürfen sich ohne vorherige schriftliche Zustimmung von Pneuhage nicht erneut für das RSM ESupply LkSG Tool registrieren.</li>
                </ol> 
            </li>
            <li>
                <strong>Haftungsbeschränkung</strong>
                <ol>
                    <li>Pneuhage haftet nur bei Vorsatz und grober Fahrlässigkeit von Pneuhage oder ihrer Erfüllungs- oder Verrichtungsgehilfen sowie im Falle einer Verletzung wesentlicher Vertragspflichten, d. h. solcher Pflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglichen und auf deren Einhaltung der Geschäftspartner regelmäßig vertrauen darf. Bei der Verletzung einer wesentlichen Vertragspflicht, welche nicht auf Vorsatz oder grober Fahrlässigkeit beruht, beschränkt sich die Haftung von Pneuhage auf den bei Vertragsschluss vorhersehbaren und typischen Schaden.</li>
                    <li>Die vorstehenden Bestimmungen zur Haftungsbeschränkung gelten nicht bei einer Verletzung des Lebens, des Körpers und der Gesundheit und für die Haftung nach zwingenden gesetzlichen Vorschriften wie z.B. denen des Produkthaftungsgesetzes.</li>
                    <li>Soweit die Schadensersatzhaftung Pneuhage gegenüber ausgeschlossen oder eingeschränkt ist, gilt dies auch im Hinblick auf die persönliche Schadenersatzhaftung der Angestellten, gesetzlichen Vertreter und Erfüllungsgehilfen von Pneuhage.</li>
                    <li>Der Geschäftspartner ist für die Einhaltung gesetzlicher Aufbewahrungspflichten allein verantwortlich. Eine Haftung von Pneuhage ist insoweit ausgeschlossen.</li>
                </ol> 
            </li>
            <li>
                <strong>Datenschutz</strong> 
                <ol>
                    <li>Es gelten die Pneuhage Datenschutzhinweise, abrufbar unter https://pneu.com/datenschutz/.</li>
                </ol> 
            </li>
            <li>
                <strong>Änderungen dieser Nutzungsbedingungen und der Services</strong>
                <ol>
                    <li>Pneuhage behält sich das Recht vor, diese Nutzungsbedingungen jederzeit mit Wirksamkeit auch innerhalb der bestehenden Vertragsverhältnisse zu ändern. Pneuhage wird dem Geschäftspartner mindestens 30 Kalendertage vor dem geplanten Inkrafttreten einer Änderung der Nutzungsbedingungen in Kenntnis setzen. </li>
                    <li>Sofern der Geschäftspartner nicht innerhalb von 30 Tagen ab Zugang der Mitteilung widerspricht, gelten die Änderungen mit Fristablauf als wirksam vereinbart. Im Falle eines Widerspruchs wird das Nutzungsverhältnis zu den bisherigen Bedingungen fortgesetzt. Pneuhage behält sich das Recht vor, im Falle eines Widerspruchs das Nutzungsverhältnis zum nächstmöglichen Zeitpunkt zu beenden. In der Änderungsmitteilung wird der Geschäftspartner auf sein Widerspruchsrecht und auf die Folgen hingewiesen.</li>
                    <li>Pneuhage behält sich zudem das Recht vor, die unentgeltlich bereitgestellten Services jederzeit zu ändern, neue Services unentgeltlich oder entgeltlich verfügbar zu machen und die Bereitstellung unentgeltlicher Services einzustellen. Pneuhage wird hierbei auf die berechtigten Interessen des Geschäftspartners Rücksicht nehmen. Ohne aktive Zustimmung des Geschäftspartners werden keine Zusatzkosten für bislang unentgeltliche Dienste anfallen.</li>
                </ol> 
            </li>
            <li>
                <strong>Schlussbestimmungen</strong>
                <ol>
                    <li>Individuelle Änderungen und Ergänzungen dieser Nutzungsbedingungen bedürfen der Schriftform. Dies gilt auch für Änderungen des Schriftformerfordernisses. Das Schriftformerfordernis wird durch E-Mail nicht gewahrt.</li>
                    <li>Es gilt deutsches Recht unter Ausschluss des deutschen Internationalen Privatrechts und des UN-Kaufrechts.</li>
                    <li>Ausschließlicher Gerichtsstand ist der Sitz von Pneuhage in Karlsruhe. RSM Ebner Stolz ist auch berechtigt, am Sitz des Kunden zu klagen.</li>
                    <li>Sollte eine Bestimmung dieser Nutzungsbedingungen unwirksam sein oder werden, so bleibt die Wirksamkeit der übrigen Bestimmungen hiervon unberührt. Die Parteien sind in einem solchen Falle verpflichtet, an der Schaffung von Bestimmungen mitzuwirken, durch die ein der unwirksamen Bestimmung wirtschaftlich möglichst nahekommendes Ergebnis rechtswirksam erzielt wird. Das Vorstehende gilt für die Schließung etwaiger Vertragslücken entsprechend.</li>
                </ol>
            </li>
        </ol>

        <p>Stand: April 2024</p>
        <p>
            Pneuhage Management GmbH & Co. KG
            <br />
            An der Rossweid 23-25
            <br />
            76229 Karlsruhe
        </p>
    </div>
</div>

<!--
<div v-else-if="$i18n.locale=='en'">
    <div class="terms-of-use">
        <ol>
            <li>
                <strong> Subject matter, legal position of RSM Ebner Stolz </strong>
                <ol>
                    <li> The RSM Ebner Stolz supplier platform is a digital platform for tenders and auctions on which RSM Ebner Stolz Management Consultants GmbH ("RSM Ebner Stolz") requests bid-relevant information on behalf of customers regarding the procurement of goods and services and potential suppliers can post corresponding bid-relevant information about their range of goods and services as registered users.</li>
                    <li> No contract is concluded between the users through the tendering or auctioning of goods or services on the RSM Ebner Stolz supplier platform or through the submission of bids for goods or services. Rather, the posting of content is solely for the purpose of preparing separate contract conclusions between RSM Ebner Stolz's customers and the providers of goods and services. RSM Ebner Stolz is not authorized to represent clients for the purchase of the goods and services advertised on this platform and does not offer to purchase the advertised goods and services itself with the bidding.<strong> All prices offered on this supplier platform by the bidders constitute a legally valid and binding offer.</strong></li>
                </ol>
            </li>

            <li>
                <strong> Registration</strong>
                <ol>
                    <li> The use of this supplier platform requires login and registration as a user by RSM Ebner Stolz. There is no entitlement to registration. Login and registration are free of charge for the user. The data requested by RSM Ebner Stolz during login and registration must be provided by the user completely and accurately. Subsequent changes to the specified data can be made by the user independently.</li>
                    <li> User names and passwords provided by RSM Ebner Stolz are intended for initial registration and must be kept secret by the user and changed upon initial registration. RSM Ebner Stolz undertakes not to disclose the password issued to the respective registered user to third parties.</li>
                </ol>
            </li>

            <li>
                <strong> Extent of Use</strong>
                <ol>
                    <li> RSM Ebner Stolz provides the RSM Ebner Stolz supplier platform within the scope of RSM Ebner Stolz's technical and operational capabilities.</li>
                    <li> Due to not more precisely foreseeable and controllable simultaneous access to the server by registered users and / or access by other Internet users and due to force majeure (including strikes and / or lockouts) as well as due to possibly necessary maintenance work and repairs of RSM Ebner Stolz's facilities, which are required for a proper and improved operation of RSM Ebner Stolz's IT system, delays, malfunctions and / or interruptions of access may occur temporarily. Maintenance and repair work will be carried out by RSM Ebner Stolz outside normal business hours if possible and the user will be notified in advance by e-mail.</li>
                </ol>
            </li>

            <li>
                <strong>Terms of Use</strong>
                <ol>
                    <li>
                        The user agrees, when using the RSM Ebner Stolz supplier platform:
                        <ul>
                            <li>To submit bids only if he is able to perform the relevant services and is expressly prepared to enter into and conclude contractual negotiations for the supply of the relevant goods or the provision of the relevant services</li>
                            <li>To comply with the program-technical instructions provided by RSM Ebner Stolz for the use of the RSM Ebner Stolz supplier platform and to refrain from any manipulation of the data and / or the program sequence</li>
                            <li> To establish and maintain necessary precautions for the regular safeguarding of the data transmitted to it via the RSM Ebner Stolz supplier platform </li>
                            <li> Immediately notify RSM Ebner Stolz of any recognizable defects, damages and / or malfunctions</li>
                        </ul>
                    </li>
                    <li> The user agrees to use the RSM Ebner Stolz supplier platform in accordance with this agreement exclusively for his own purposes; transfer of use to third parties is not permitted.</li>
                    <li> There is no right to enter into contract negotiations or to conclude a contract for the advertised and / or offered services.</li>
                </ol>
            </li>

            <li>
                <strong> Compliance with legal requirements, third-party property rights, indemnification, blocking</strong>
                <ol>
                    <li> The user agrees to comply with legal and official regulations, in particular data protection law, criminal law, copyright and / or trademark law and / or other regulations of industrial property protection, and not to violate any rights of third parties with regard to the data contained on the RSM Ebner Stolz supplier platform and the entry and retrieval of data via the RSM Ebner Stolz supplier platform.    </li>
                    <li> RSM Ebner Stolz will inform users without delay within the scope of what is legally permissible if third parties or authorities assert against it that a violation of statutory and / or official regulations or a violation of third party rights attributable to the users pursuant to section 5.1 exists.</li>
                    <li> The user shall indemnify RSM Ebner Stolz against any liability arising from an infringement within the meaning of section 5.1 and shall support RSM Ebner Stolz in its legal defense to the best of its ability. If the infringement of rights attributed to RSM Ebner Stolz is based on the fact that data, designs and / or other information made accessible online by the user or at the instigation of the user of RSM Ebner Stolz infringe copyrights, trademark rights and / or other industrial property rights of third parties, RSM Ebner Stolz may require the user to assume any amounts of damages and costs of reasonable legal defense, provided RSM Ebner Stolz is not guilty of contributory negligence.li>
                    <li> If there is sufficient suspicion that a violation within the meaning of section 5.1 has occurred, RSM Ebner Stolz shall be entitled to block the RSM Ebner Stolz supplier platform for the User until proof of legality has been provided.</li>
                </ol>
            </li>

            <li>
                <strong> Responsibility for Content, Data Protection</strong>
                <ol>
                    <li> RSM Ebner Stolz does not assume any warranty and / or guarantee for the description, properties, prices and the needs of customers for the goods and services advertised on the RSM Ebner Stolz supplier platform and for the conclusion of a contract between customers and users of the RSM Ebner Stolz supplier platform who have submitted a bid.</li>
                    <li> RSM Ebner Stolz has no control over the quality, safety and / or legality of the goods and services posted by users as bids on the RSM Ebner Stolz supplier platform, nor does it verify them within the scope of these Terms of Use. The same applies to the correctness of the content of the corresponding bids and the ability of the users to deliver the relevant goods or provide services.</li>
                    <li> In connection with the operation of the RSM Ebner Stolz supplier platform, RSM Ebner Stolz collects, processes and uses the personal data as well as the usage data of the user in automated procedures.</li>
                </ol>
            </li>

            <li>
                <strong> Limitation of Liability</strong>
                <ol>
                    <li> Unless otherwise provided for in clauses 7.2 and 7.3 below, RSM Ebner Stolz shall not be liable for damages - irrespective of the legal nature of the asserted claim and even after expiry of any deadline set for performance. Subject to clauses 7.2 and 7.3 below, RSM Ebner Stolz shall in particular not be liable for the information retrievable and entered via the RSM Ebner Stolz supplier platform - neither for its correctness, completeness or up-to-dateness, nor for its legality or unencumberedness by third party rights - nor for damages to the user's legal assets as well as for lost profits, lost savings, damages from third party claims and other indirect consequential damages.</li>
                    <li> RSM Ebner Stolz shall be liable for all financial losses caused by the provision of telecommunication services pursuant to Section 7 (2) TKV up to an amount of EUR 12,500 per user. RSM Ebner Stolz's liability towards the injured parties as a whole shall be limited to EUR 10 million per event causing damage. If the compensation to be paid to several parties due to the same event exceeds the maximum limit, the compensation shall be reduced in the ratio in which the sum of all compensation claims stands to the maximum limit. The limitation of liability in terms of amount does not apply if the damage was caused intentionally.</li>
                    <li> With the exception of financial losses resulting from the provision of telecommunication services pursuant to Section 7 (2) TKV, the limitations of liability pursuant to Section 7.1 shall not apply to bodily injury, damage to health and life as well as personal injury and property damage pursuant to the Product Liability Act and for damages for which RSM Ebner Stolz is responsible due to intent or gross negligence and not for the absence of warranted characteristics. Furthermore, they shall not apply in the event of fraudulent concealment of a defect or the assumption of a quality guarantee and / or for damages that have arisen as a result of slightly negligent violation of essential contractual obligations (cardinal obligations). In this respect, RSM Ebner Stolz shall be liable without limitation in accordance with the statutory provisions.</li>
                </ol>
            </li>

            <li>
                <strong> Contract term, Termination </strong>
                <ol>
                    <li> This agreement may be terminated by either Party upon 3 days' written notice.</li>
                    <li> The right to terminate for important reasons remains unaffected.</li>
                </ol>
            </li>

            <li>
                <strong> Final Provisions</strong>
                <ol>
                    <li> This contract is subject to German substantive law. The application of the UN Convention on Contracts for the International Sale of Goods is excluded.</li>
                    <li> If the user is a merchant or has no general place of jurisdiction in Germany, Cologne shall be the exclusive place of jurisdiction for all disputes between the user and RSM Ebner Stolz.</li>
                </ol>
            </li>
        </ol>
    </div>
</div>
-->
<div v-else-if="$i18n.locale=='xx'">
    <div class="terms-of-use">
        Platzhalter für klingonischen Text.
</div>
</div>

</template>
<script>
export default {
    name: 'termsOfService',
};
</script>


Senior Manager im Team MC, Köln
T: +4922120643947 | M: +491732501031 | in Teams anschreiben
