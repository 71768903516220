<template>
    <b-card title="Risiken eigener Geschäftsbereich" class="mb-3 shadow">
        <b-button v-b-toggle.help_risks_egb pill size="sm" variant="outline-secondary" class="info-button" v-if="!hasRole('Lieferant')"><em>i</em></b-button>                  
        <b-sidebar right id="help_risks_egb" :title="$t(`help.info`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">
            <div class="px-3 py-2">
                <b-list-group>
                    <b-list-group-item></b-list-group-item>
                    <b-list-group-item></b-list-group-item>
                    <b-list-group-item></b-list-group-item>
                    <b-list-group-item></b-list-group-item>
                </b-list-group>
            </div>
        </b-sidebar>

        <b-form-group v-model="searchFilter2" label="Filter" class="mb-3" v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group v-model="searchFilter2" :aria-describedby="ariaDescribedby" class="mt-1">
                <b-form-checkbox v-for="year in years" :key="year.id" :value="year.year">{{ year.year }}</b-form-checkbox>
            </b-form-checkbox-group>
        </b-form-group>

        <div class="table-scrollable">
            <b-table striped hover :items="filteredSupplierRisks" :fields="fields" responsive="sm">
                <template #cell(completed)="data">
                    <div style="width: 10rem;">
                        <b-progress :max="data.item.questionnaire_score.count">
                            <b-progress-bar :value="data.item.questionnaire_score.count_completed">
                            <span>{{ data.item.questionnaire_score.count_completed }}</span>
                            </b-progress-bar>
                        </b-progress>
                    </div>
                </template>
            </b-table>
        </div>
    </b-card>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import riskService from '../../../../../services/Riskmanagement/RiskService';

export default {
    name: 'egbrisktab',
    components: {},
    props: [],
    data() {
        return {
            searchFilter: 'eigener Geschäftsbereich',
            searchFilter2: [],
            supplierrisks: [],
            selectedYear: null,
            score_high: 20,
            score_middle: 10,
            score_low: 5,
            score_max: 50,
        }
    },
    created() {
        this.fetchYears();
        this.fetchSupplierRisks();
    },
    methods: {
        ...mapActions({
            fetchYears: 'years/fetch',
        }),
        fetchSupplierRisks() {
            this.$store.commit('loading/setLoading', true);
                riskService.listSupplierRisks()
                    .then(response => {
                        this.supplierrisks = response;
                        this.$store.commit('loading/setLoading', false);
                    }).catch(error => {
                        this.$store.commit('loading/setLoading', false);
                    });
        }
    },
    computed: {
        ...mapGetters({
            hasRole: 'auth/hasRole',
            years: 'years/years'
        }),
        filteredSupplierRisks() {
            return this.supplierrisks.filter(item => {
                const filter1Match = !this.searchFilter || item.lksg_scope === this.searchFilter;
                const filter2Match = this.searchFilter2.length === 0 || this.searchFilter2.includes(item.year.year);
                return filter1Match && filter2Match;
            });
        },
        fields() {
            return [
                //{ key: 'ID', sortable: true },
                { key: 'year.year',                             label: 'Jahr',              sortable: true },
                { key: 'display_name',                          label: 'Gesellschaft',      sortable: true },
                { key: 'lksg_scope',                            label: 'LkSG-Scope',        sortable: true },
                { key: 'country.country_name',                  label: 'Land',              sortable: true },
                { key: 'questionnaire_score.score_value',       label: 'Fragebogen Score',  sortable: true },
                { key: 'completed',                             label: 'Bearbeitungsstatus',sortable: true },
            ];
        },
    },
}
</script>