<template>
    <div class="card" style="border-width: 0px">
        <div class="card-header px-0" style="background-color: #f4f5fa">
            {{ $t("questionnaire.list_header") }}
        </div>
        <div class="card-body px-0" style="background-color: #f4f5fa">
            <ListCard />
        </div>
        <b-modal ref="confirmationModal" hide-footer :title="$t(`questionnaire.modal_questionnaire_title`)">
            <div class="d-block text-center">
                <p >{{ $t("questionnaire.modal_questionnaire_delete1") }}<strong>'{{itemToDelete.name}}'</strong>{{ $t("questionnaire.modal_questionnaire_delete2") }}</p>
            </div>
            <b-button class="button-margin mt-3" variant="outline-danger" block @click="deleteQuestionnaire">{{ $t("questionnaire.btn_delete") }}</b-button>
        </b-modal>
</div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import QuestionnaireService from '../../../../services/QuestionnaireService';
import ListCard from './modules/ListCard';

export default {
    name: 'questionnaire-list',
    components: {
        ListCard,
    },
    data() {
        return {
            itemToDelete: {},
        }
    },
    created() {
        this.list();
    },
    watch: {
        '$route': 'list'
    },
    methods: {
        ...mapActions({
            list: 'questionnaires/list',
        }),
        requestDelete(item) {
            this.itemToDelete = item;
            this.$refs.confirmationModal.show();
        },
        deleteQuestionnaire() {
            QuestionnaireService.deleteQuestionnaire(this.itemToDelete.id)
                .then(response => {
                    this.$refs.confirmationModal.hide();
                    this.itemToDelete = {};
                    if(response.data.success) {
                        this.$notify({
                            group: 'global',
                            title: this.$t("questionnaire.notifiy_deletequestionnaire_title"),
                            type: 'success',
                            text: this.$t("questionnaire.notifiy_deletequestionnaire_message")
                        });

                        this.list();

                    }else {
                        this.$notify({
                            group: 'global',
                            title: this.$t("questionnaire.notifiy_deletequestionnaire_error"),
                            type: 'error',
                            text: response.data.message
                        });
                    }
                });

        },
        moveItemUp(questionnaireId) {
            this.$store.commit('loading/setLoading', true);
            QuestionnaireService.moveQuestionnaireUp(questionnaireId)
                .then(response => {
                    this.$store.commit('loading/setLoading', false);
                    this.list();
                });
        },
        moveItemDown(questionnaireId) {
            this.$store.commit('loading/setLoading', true);
            QuestionnaireService.moveQuestionnaireDown(questionnaireId)
                .then(response => {
                    this.$store.commit('loading/setLoading', false);
                    this.list();
                });
        }
    },
    computed: {
        ...mapGetters({
            questionnaires: 'questionnaires/questionnaires',
            hasRole: 'auth/hasRole'
        }),
        fields() {
            return [
                {
                    key: 'order',
                    label: this.$t(`questionnaire.label_order`)
                },    
                {
                    key: 'name',
                    label: this.$t(`questionnaire.label_name`)
                },
                {
                    key: 'request',
                    label: this.$t(`questionnaire.label_request`)
                },
                {
                    key: 'year.year',
                    label: this.$t(`questionnaire.label_year`)
                },
                {
                    key: 'version',
                    label: this.$t(`questionnaire.label_version`)
                },
                {
                    key: 'status',
                    label: this.$t(`questionnaire.label_status`)
                },
                {
                    key: 'actions',
                    label: this.$t(`questionnaire.label_actions`)
                }
            ];
        },

    }
}
</script>
