import axios from 'axios';

export default {
    list() {
        return axios.get(window.applicationSettings.apiEndpoint + '/questionnaire/list')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    create(name) {
        return axios.post(window.applicationSettings.apiEndpoint + '/questionnaire/create', {name: name})
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    update(questionnaire) {
        return axios.post(window.applicationSettings.apiEndpoint + '/questionnaire/update/'+questionnaire.id, questionnaire)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    fetch(questionnaireId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/questionnaire/fetch/'+questionnaireId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    fetchQuestions(questionnaireId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/questionnaire/fetchQuestions/'+questionnaireId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    createQuestion(questionObject, questionnaireId) {
        return axios.post(window.applicationSettings.apiEndpoint+'/questionnaire/'+questionnaireId+'/createQuestion', questionObject)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    updateQuestion(questionObject) {
        return axios.post(window.applicationSettings.apiEndpoint+'/questionnaire/question/update/'+questionObject.id, questionObject)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    fetchForAuctions(auctionId) {
        return axios.get(window.applicationSettings.apiEndpoint+'/questionnaire/fetchForAuction/'+auctionId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    fetchForMe() {
        return axios.get(window.applicationSettings.apiEndpoint + '/questionnaire/fetchForMe')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    fetchForMeByAuction(auctionId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/questionnaire/fetchForMe/'+auctionId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    fetchForUser(userId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/questionnaire/fetchForUser/' + userId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    saveQuestions(questionnaireId, values) {
        let formdata = new FormData();
     
        for(let value in values) {
            if(values.hasOwnProperty(value)) {
                formdata.append(value, values[value]);
            }
        }

        return axios.post(window.applicationSettings.apiEndpoint+'/questionnaire/saveQuestions/'+questionnaireId, formdata)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    fetchMyAnswers() {
        return axios.get(window.applicationSettings.apiEndpoint+'/questionnaire/fetchMyAnswers')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    fetchAnswersForUser(userId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/questionnaire/fetchAnswersForUser/'+userId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    fetchAssignedAuctions(questionnaireId) {
        return axios.get(window.applicationSettings.apiEndpoint+'/questionnaire/fetchAssignedAuctions/'+questionnaireId)
        .then(response => response.data)
        .catch(error => Promise.reject(error.response));
    },
    deleteQuestionnaire(questionnaireId) {
        return axios.delete(window.applicationSettings.apiEndpoint+'/questionnaire/delete/'+questionnaireId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    deleteQuestion(questionnaireId, questionId) {
        return axios.delete(window.applicationSettings.apiEndpoint+'/questionnaire/deleteQuestion/'+questionnaireId+'/'+questionId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    downloadQuestionnaire(questionnaireId) {
        return axios.get(window.applicationSettings.apiEndpoint+'/questionnaire/download/'+questionnaireId, {responseType: 'blob'})
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    downloadFile(downloadKey) {
        return axios.get(window.applicationSettings.apiEndpoint+'/questionnaire/downloadFile/'+downloadKey, {responseType: 'blob'})
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    moveQuestionnaireUp(questionnaireId) {
        return axios.post(window.applicationSettings.apiEndpoint+'/questionnaire/moveUp/'+questionnaireId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    moveQuestionnaireDown(questionnaireId) {
        return axios.post(window.applicationSettings.apiEndpoint+'/questionnaire/moveDown/'+questionnaireId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },

    fetchAnsweroptions(questionId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/questionnaire/fetchAnsweroptions/' + questionId)
            .then(response => response.data)
            .catch(error => error.response);
    },
    createAnsweroption(questionId, payload) {
        var formData = new FormData();
        formData.append('data', JSON.stringify(payload));
        return axios.post(window.applicationSettings.apiEndpoint + '/questionnaire/createAnsweroption/'+ questionId, formData)
            .then(response => response.data)
            .catch(error => error.response);
    },
    updateAnsweroption(questionId, answeroptionId, payload) {
        var formData = new FormData();
        formData.append('data', JSON.stringify(payload));
        return axios.post(window.applicationSettings.apiEndpoint + '/questionnaire/updateAnsweroption/'+ questionId + '/' + answeroptionId, formData)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    deleteAnsweroption(questionId, answeroptionId) {
        return axios.delete(window.applicationSettings.apiEndpoint + '/questionnaire/deleteAnsweroption/'+ questionId + '/' + answeroptionId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    moveUpAnsweroption(questionId, answeroptionId) {
        return axios.post(window.applicationSettings.apiEndpoint+'/questionnaire/moveUpAnsweroption/'+ questionId + '/' + answeroptionId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    moveDownAnsweroption(questionId, answeroptionId) {
        return axios.post(window.applicationSettings.apiEndpoint+'/questionnaire/moveDownAnsweroption/'+ questionId + '/' + answeroptionId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    }
}